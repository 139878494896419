import React, { useState } from 'react';
import './Popup.css';
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { IoIosWarning } from "react-icons/io";

import { MdCancel } from "react-icons/md";
import CommonButton from '../button/CommonButton';

const Popup = (props) => {

    const [deletePopup, setDeletePopup] = useState(false)

    // const handlePopupCancel = () => {
    //     setDeletePopup(false);
    // };

    return (
        <div className='deletePopupAlertWrapper popup-box' id='deletePopupAlertWrapper'>
            {
                props.isItConfimationPopup ? (
                    <div className="deletePopupAlert">
                        <IoIosWarning style={{ color: "var(--Orange)", fontSize: "40px" }} />

                        {/* <h4>{props.status ? "Done" : "Failed"}</h4> */}
                        <div className="deleteAlertTextWrapper text-center">
                            {/* <p>{props.message && props.message}</p> */}
                            <p>Are you sure?</p>
                        </div>

                        <div className="deleteBtnsWrapper">
                            <CommonButton btnClass='btn-edit bg-red text-capitalize' btnName={props.buttonName ? props.buttonName :'Delete'} onClickAction={props.handleDelete} />
                            <CommonButton btnClass='btn-edit' btnName='Close' onClickAction={props.hidePopup} />
                        </div>
                    </div>
                ) : (
                    <div className="deletePopupAlert">
                        {
                            props.status ? (
                                <IoCheckmarkDoneCircle style={{ color: "var(--Green)", fontSize: "40px" }} />
                            ) : (
                                <MdCancel style={{ color: "var(--Red)", fontSize: "40px" }} />
                            )
                        }
                        <h4>{props.status ? "Done" : "Failed"}</h4>
                        <div className="deleteAlertTextWrapper text-center">
                            <p>{props.message && props.message}</p>
                            {/* <p>Are you sure?</p> */}
                        </div>

                        <div className="deleteBtnsWrapper">
                            <CommonButton btnClass='btn-edit' btnName='Close' onClickAction={props.hidePopup} />
                        </div>
                    </div>
                )
            }

        </div>
    );
}

export default Popup
