import { Container, Row } from "react-bootstrap";
import "./GeneralTable.css";
import React, { useState } from "react";
import CommonButton from "../button/CommonButton";
import NoResults from "../no-results/NoResults";
import useCurrentUser from "../../custom-hooks/useCurrentUser";

// const GeneralTable = ({ rows, columns }) => {
//   const renderCellContent = (value, isComparisonRow) => {
//     if (
//       isComparisonRow &&
//       value &&
//       typeof value === "object" &&
//       "value" in value &&
//       "isPositive" in value
//     ) {
//       return (
//         <span
//           style={{ color: value.isPositive ? "var(--Green)" : "var(--Red)" }}
//         >
//           {value.value}
//         </span>
//       );
//     }
//     return value;
//   };
//   return (
//     <section className="sm-kpi-table-wrapper">
//       <Container>
//         <Row>
//           <table className="kpi-table">
//             <thead>
//               <tr>
//                 {columns?.map((column, cIndex) => (
//                   <th key={cIndex}>{column?.header}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {rows?.map((row, rowIndex) => (
//                 // console.log("row ==>", row)
//                 <tr key={rowIndex}>
//                   {/* {Object.keys(row).map((property, index) => (
//                     <td key={index}>
//                       {
//                       renderCellContent(
//                         row[property],
//                         rowIndex === columns.length - 1
//                       )
//                       }
//                     </td>
//                   ))} */}
//                   {columns?.map((column, colIndex) => (
//                     <td key={colIndex}>
//                       {renderCellContent(
//                         row[column.field],
//                         rowIndex === columns.length - 1
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Row>
//       </Container>
//     </section>
//   );
// };
// export default GeneralTable;
const GeneralTable = ({ headers, tableData, rowsPerPage, onButtonCLick, tableType, showActionButtons }) => {

  const [currentPage, setCurrentPage] = useState(1);

  rowsPerPage = rowsPerPage ? rowsPerPage : 10;

  // Calculate total pages
  const totalPages = Math.ceil(tableData?.length / rowsPerPage);

  // Get data for the current page
  const currentTableData = tableData?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentUser = useCurrentUser();

  return (
    <section className="sm-kpi-table-wrapper">
      <Container>
        <Row>
          {
            currentTableData?.length > 0 ? (
              // <table className="kpi-table">
              //   <thead>
              //     <tr>
              //       <th>#</th>
              //       {
              //         headers?.map((header, hIndex) => (
              //           <th key={hIndex}>{header?.headerName}</th>
              //         ))}
              //     </tr>
              //   </thead>
              //   <tbody>
              //     {
              //       currentTableData?.map((rowData, rowIndex) => (
              //         <tr key={rowIndex}>
              //           <td>
              //             {(currentPage - 1) * rowsPerPage + rowIndex + 1}
              //           </td>{" "}
              //           {/* Serial number column */}
              //           {headers?.map((header, cellIndex) => {
              //             const cellValue = rowData[header.valueToBeRender] || "-"; // Fallback value
              //             return (
              //               <td key={cellIndex} className={header?.className ? header?.className : ""}>
              //                 {/* Check if renderFunction exists, else render the plain value */}
              //                 {header.renderFunction
              //                   ? header.renderFunction({ value: cellValue, rowData })
              //                   : cellValue}
              //               </td>
              //             );
              //           })}
              //         </tr>
              //       ))
              //     }
              //   </tbody>
              // </table>


              // <table className="kpi-table">
              //   <thead>
              //     <tr>
              //       <th>#</th>
              //       {headers?.map((header, hIndex) => (
              //         <th key={hIndex}>
              //           {header?.headerName}
              //           {header?.subHeaders?.length > 0 && (
              //             <table className="nested-header">
              //               <thead>
              //                 <tr>
              //                   {header.subHeaders.map((subHeader, subIndex) => (
              //                     <th key={subIndex}>{subHeader.headerName}</th>
              //                   ))}
              //                 </tr>
              //               </thead>
              //             </table>
              //           )}
              //         </th>
              //       ))}
              //     </tr>
              //   </thead>
              //   <tbody>
              //     {currentTableData?.map((rowData, rowIndex) => (
              //       <tr key={rowIndex}>
              //         <td>{(currentPage - 1) * rowsPerPage + rowIndex + 1}</td>
              //         {headers?.map((header, cellIndex) => {
              //           if (header?.subHeaders?.length > 0) {
              //             return (
              //               <td key={cellIndex} className={header?.className || ""}>
              //                 <table className="nested-cell">
              //                   <tbody>
              //                     <tr>
              //                       {header?.subHeaders?.map((subHeader, subIndex) => {
              //                         const subCellValue = rowData[subHeader.valueToBeRender];
              //                         return (
              //                           <td key={subIndex} className={subHeader.className || ""}>
              //                             {subHeader.renderFunction
              //                               ? subHeader.renderFunction(subCellValue, rowData)
              //                               : subCellValue
              //                                 ? <span className="check-icon">✔</span>
              //                                 : <span className="cross-icon">✘</span>}
              //                           </td>
              //                         );
              //                       })}
              //                     </tr>
              //                   </tbody>
              //                 </table>
              //               </td>
              //             );
              //           } else {
              //             const cellValue = rowData[header?.valueToBeRender] || "-";
              //             return (
              //               <td key={cellIndex} className={header?.className || ""}>
              //                 {header.renderFunction
              //                   ? header.renderFunction(cellValue, rowData)
              //                   : cellValue}
              //               </td>
              //             );
              //           }
              //         })}
              //       </tr>
              //     ))}
              //   </tbody>
              // </table>

              // ==========below is the new format for showing leave data =============
              <table className="kpi-table">
                <thead>
                  <tr>
                    <th>#</th>
                    {headers?.map((header, hIndex) => (
                      <th key={hIndex}>{header?.headerName}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {tableType !== "leave" &&
                    currentTableData?.map((rowData, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>{(currentPage - 1) * rowsPerPage + rowIndex + 1}</td>
                        {headers?.map((header, cellIndex) => {
                          if (header?.subHeaders?.length > 0) {
                            return (
                              <td key={cellIndex} className={header?.className || ""}>
                                <table className="nested-cell">
                                  <tbody>
                                    <tr>
                                      {header?.subHeaders?.map((subHeader, subIndex) => {
                                        const subCellValue = rowData[subHeader.valueToBeRender];
                                        return (
                                          <td key={subIndex} className={subHeader.className || ""}>
                                            {subHeader.renderFunction
                                              ? subHeader.renderFunction(subCellValue, rowData)
                                              : subCellValue
                                                ? <span className="check-icon">✔</span>
                                                : <span className="cross-icon">✘</span>}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            );
                          } else {
                            const cellValue = rowData[header?.valueToBeRender] || "-";
                            return (
                              <td key={cellIndex} className={header?.className || ""}>
                                {header.renderFunction
                                  ? header.renderFunction(cellValue, rowData)
                                  : cellValue}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}

                  {tableType == "leave" && currentTableData?.map((rowData, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{(currentPage - 1) * rowsPerPage + rowIndex + 1}</td>

                      {headers?.map((header, cellIndex) => {
                        const cellValue = rowData[header?.valueToBeRender] || "-";

                        if (tableType === "leave" && header.valueToBeRender === "days") {
                          return (
                            <td key={cellIndex} className={header?.className || "p-0"}>
                              {rowData.days.map((day, dayIndex) => (
                                <tr className="leave-inner-data-row" key={dayIndex}>
                                  <td>{new Date(day.date).toLocaleDateString()}</td>
                                  <td>{day.nameOfTheDay}</td>
                                  <td>{day.typeOfLeave}</td>
                                </tr>
                              ))}
                            </td>
                          );
                        }

                        if (tableType === "leave" && header.valueToBeRender === "status") {
                          return (
                            <td key={cellIndex} className={header?.className || ""}>
                              {
                                // (showActionButtons) ? (
                                (showActionButtons && cellValue === "pending") ? (
                                  <div className="leave-table-action-btns-wrapper">
                                    <button
                                      className="approve-btn leave-action-btn"
                                      onClick={() => onButtonCLick(true, rowData?._id, "approved")}
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="reject-btn leave-action-btn"
                                      onClick={() => onButtonCLick(true, rowData?._id, "rejected")}
                                    >
                                      Reject
                                    </button>
                                  </div>
                                ) : (
                                  cellValue
                                )
                              }
                            </td>
                          );
                        }

                        return (
                          <td key={cellIndex} className={header?.className || ""}>
                            {header.renderFunction
                              ? header.renderFunction(cellValue, rowData)
                              : cellValue}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>

            ) : (
              <NoResults />
            )
          }
        </Row>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="pagination-controls">
            <CommonButton
              isDisable={currentPage === 1}
              onClickAction={() => handlePageChange(currentPage - 1)}
              btnName="Previous"
              btnClass="btn-edit"
            />
            {/* {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))} */}
            <CommonButton
              isDisable={currentPage === totalPages}
              onClickAction={() => handlePageChange(currentPage + 1)}
              btnName="Next"
              btnClass="btn-edit"
            />
            {/* <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button> */}
          </div>
        )}
      </Container>
    </section>
  );
};



// ==================old method to render table's row and cell =====================
// const GeneralTable = ({ headers, smData }) => {
//   const renderCellContent = (value, isComparisonRow) => {
//     if (
//       isComparisonRow &&
//       value &&
//       typeof value === "object" &&
//       "value" in value &&
//       "isPositive" in value
//     ) {
//       return (
//         <span
//           style={{ color: value.isPositive ? "var(--Green)" : "var(--Red)" }}
//         >
//           {value.value}
//         </span>
//       );
//     }
//     return value;
//   };
//   return (
//     <section className="sm-kpi-table-wrapper">
//       <Container>
//         <Row>
//           <table className="kpi-table">
//             <thead>
//               <tr>
//                 {headers?.map((header, hIndex) => (
//                   <th key={hIndex}>{header}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {smData?.map((cell, cellIndex) => (
//                 <tr key={cellIndex}>
//                   {Object.keys(cell).map((property, index) => (
//                     <td key={index}>
//                       {renderCellContent(
//                         cell[property],
//                         cellIndex === smData.length - 1
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Row>
//       </Container>
//     </section>
//   );
// };
export default GeneralTable;
