import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import "./ManageProject.css";
import "../../../../common-components/form/DynamicForm.css";
import NewTaskTable from "../../../../common-components/new-task-table/NewTaskTable";
import CommonButton from "../../../../common-components/button/CommonButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProjectFailure,
  updateProjectStart,
  updateProjectSuccess,
} from "../../../../redux/projectRedux";
import {
  deepEqualCheckForTwoArray,
  priorityOptions,
  statusOptions,
} from "../../../../config";
import Popup from "../../../../common-components/popup/Popup";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import { userRequest } from "../../../../requestMethod";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { getAllEmployees, getAllProjects } from "../../../../redux/apiCalls";
import DropDownButton from "../../../../common-components/dropdown-button/DropDownButton";
import useCurrentUser from "../../../../custom-hooks/useCurrentUser";
import { IoIosAddCircle } from "react-icons/io";
import { IoSave } from "react-icons/io5";

const ManageProject = () => {
  const { id: projectId } = useParams();

  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAllEmployees(dispatch);
  }, [dispatch]);

  const currentProject = useSelector((state) =>
    state.projects?.projects?.find((project) => project._id === projectId)
  );

  const isProjectFetching = useSelector((state) => state.projects.isFetching);

  const allProjects = useSelector((state) => state.projects?.projects);
  const { employees: allEmployees, isFetching: isEmployeesFetching } =
    useSelector((state) => state.employee);
  const [tasks, setTasks] = useState(currentProject?.tasks || []);
  const [teams, setTeams] = useState(currentProject?.teams || []);
  // const [editingTaskId, setEditingTaskId] = useState(null);
  // const [editedTask, setEditedTask] = useState({
  //   summary: "",
  //   endDate: "",
  //   status: "",
  //   progress: 0,
  // });
  const [isEligibleForSave, setIsEligibleForSave] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Tasks has been updated.");
  const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  // State for active filters
  const [activeFilters, setActiveFilters] = useState({
    user: null,
    priority: null,
    status: null,
  });

  // Reset all filters
  const resetFilters = () => {
    setActiveFilters({
      user: null,
      priority: null,
      status: null,
    });
    setSelectedUser(null);
    setSelectedPriority(null);
    setSelectedStatus(null);
    setTasks(currentProject?.tasks || []);
  };

  useEffect(() => {
    resetFilters();
  }, [projectId]);
  // const applyFilters = () => {
  //   let filtered = [...allEmployees];

  //   // Filter by department
  //   if (activeFilters?.department) {
  //     filtered = filtered.filter(
  //       (employee) => employee.department === activeFilters.department
  //     );
  //   }

  //   // Filter by status
  //   if (activeFilters?.status) {
  //     filtered = filtered.filter(
  //       (employee) => employee.employeeStatus === activeFilters.status
  //     );
  //   }

  //   // Sort by date
  //   if (activeFilters?.dateOrder) {
  //     filtered.sort((a, b) => {
  //       const dateA = new Date(a.createdAt);
  //       const dateB = new Date(b.createdAt);
  //       return activeFilters.dateOrder === "New to Old"
  //         ? dateB - dateA
  //         : dateA - dateB;
  //     });
  //   }
  //   setFilteredEmployees(filtered);
  // };

  const applyFilters = () => {
    let filteredTasks = [...(currentProject?.tasks || [])];

    if (activeFilters?.user) {
      filteredTasks = filteredTasks?.filter(
        (task) => task.assignedTo?.id === selectedUser
      );
    }

    if (activeFilters?.priority) {
      filteredTasks = filteredTasks?.filter(
        (task) => task?.priority === selectedPriority
      );
    }

    if (activeFilters?.status) {
      filteredTasks = filteredTasks?.filter(
        (task) => task?.status === selectedStatus
      );
    }

    setTasks(filteredTasks); // Update the tasks array to render the filtered tasks
  };

  const handleUserFilter = (user) => {
    setSelectedUser(user);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      user,
    }));
    // applyFilters();
  };

  const handlePriorityFilter = (priority) => {
    setSelectedPriority(priority);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      priority,
    }));
    // applyFilters();
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      status,
    }));
    // applyFilters();
  };

  // Reapply filters whenever activeFilters changes
  useEffect(() => {
    applyFilters();
  }, [activeFilters]);

  // Function to extract the team and project leads data
  const getTeamAndLeads = (currentProject, loggedInUserId) => {
    const allIds = new Set([
      ...(currentProject?.teams || []),
      ...(currentProject?.projectLeads || []),
    ]);

    // Convert to array and prioritize "My Task" (logged-in user)
    const sortedIds = [...allIds]?.sort((id) =>
      id === loggedInUserId ? -1 : 1
    );

    return sortedIds;
  };

  const getEmployeeIdAndName = (employeeId) => {
    if (allEmployees.length > 0) {
      const employee = allEmployees.find((emp) => emp._id === employeeId);
      if (employee) {
        return {
          id: employee._id,
          name: employee.name,
        };
      }
    }
    return null; // Return null if no matching employee is found
  };

  const getEmployeeNamesAndIdsForSortingTheTasks = (
    userIds,
    allEmployees,
    loggedInUserId
  ) => {
    const employees = userIds
      .map((id) => {
        const employee = allEmployees.find((emp) => emp._id === id);
        return employee
          ? {
            label: id === loggedInUserId ? "My Task" : employee.name,
            value: employee._id,
          }
          : null;
      })
      .filter(Boolean); // Remove nulls

    return employees;
  };

  // Extract unique team and lead IDs
  const teamAndLeadsIds = getTeamAndLeads(currentProject, currentUser._id);

  // Match IDs with employee names
  const employeeOptions = getEmployeeNamesAndIdsForSortingTheTasks(
    teamAndLeadsIds,
    allEmployees,
    currentUser._id
  );
  // Match employee names and IDs
  // const employeeOptions = getEmployeeNamesAndIdsForSortingTheTasks(uniqueEmployeeIds, allEmployees, currentUser._id);

  const handleEmployeeFilter = (selectedEmployeeId) => {
    const filteredTasks =
      selectedEmployeeId === currentUser._id
        ? currentProject?.tasks?.filter(
          (task) => task.assignedTo.id === currentUser._id
        )
        : currentProject?.tasks?.filter(
          (task) => task.assignedTo.id === selectedEmployeeId
        );

    setSelectedEmployeeName(getEmployeeIdAndName(selectedEmployeeId)?.name);

    setTasks(filteredTasks); // Update the task list in the table
  };

  // useEffect(() => {
  //   handleEmployeeFilter()
  // })

  useEffect(() => {
    const areTasksEqual = deepEqualCheckForTwoArray(
      currentProject?.tasks,
      tasks
    );
    setIsEligibleForSave(!areTasksEqual);
  }, [currentProject?.tasks, tasks]);

  useEffect(() => {
    setTasks(currentProject?.tasks);
  }, [currentProject?.tasks]);

  useEffect(() => {
    setTeams(currentProject?.teams);
  }, [currentProject?.teams]);

  const handleAddTask = () => {
    const newTasks = [...tasks];

    newTasks.push({
      _id: null, // null indicates it's a new task
      summary: "",
      endDate: "",
      priority: "",
      status: "",
      progress: 0,
      isOnEditMode: true,
      assignedTo: "",
      addedBy: "You",
    });
    setTasks(newTasks);
  };

  const handleEditClick = (task) => {
    const updatedTasks = tasks?.map((t) =>
      t._id === task._id ? { ...t, isOnEditMode: true } : t
    );
    setTasks(updatedTasks);
  };

  const handleDeleteClick = (taskIndex) => {
    const tasksCopy = [...tasks];
    if (tasksCopy.length > 0) {
      tasksCopy[taskIndex] = {
        ...tasksCopy[taskIndex],
        isDeleted: true,
      };
      setTasks(tasksCopy);
    }
  };

  const handleInputChange = (e, taskIndex) => {
    const { name, value } = e.target;
    if (name === "assignedTo") {
      // Find the team member's details using their ID
      const selectedTeamMember = getEmployeeIdAndName(value);

      const updatedTasks = tasks?.map((task, index) =>
        index === taskIndex
          ? {
            ...task,
            assignedTo: {
              id: value,
              name: selectedTeamMember?.name || "N.A.",
            },
          }
          : task
      );
      setTasks(updatedTasks);
    } else {
      // For other fields
      const updatedTasks = tasks?.map((task, index) =>
        index === taskIndex ? { ...task, [name]: value } : task
      );
      setTasks(updatedTasks);
    }
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate(); // useHistory hook to programmatically navigate

  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  // Handle navigation to selected project
  const handleSelectProject = (projectId) => {
    // Navigate to the selected project's page
    navigate(`/manage-project/${projectId}`, { replace: true });
    setDropdownVisible(false); // Close the dropdown after selection
  };

  const handleSaveChanges = async (finalData) => {
    setIsLoaderActive(true);
    try {
      dispatch(updateProjectStart());
      const res = await userRequest.put(
        `/project/add-task/${projectId}`,
        finalData
      );
      if (res?.status === 200) {
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText("Tasks have been updated");
        dispatch(updateProjectSuccess(res.data));
      }
    } catch (error) {
      dispatch(updateProjectFailure());
      setIsLoaderActive(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error.message);
    }
  };

  const handleSaveTasks = () => {
    handleSaveChanges(tasks);
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  if (isProjectFetching || isEmployeesFetching) {
    return <MiniLoader />;
  }

  return (
    <>
      <div className="breadcrumb-pg-header-container">
        <Breadcrumb
          mainTitle="Manage Project"
          customClass="breadcrumb-custom"
        />
      </div>

      <div className="mp-project-name-wrapper custom-padding">
        <Container>
          <Row className="align-items-center">
            <div className="col-lg-6 p-0">
              <div className="manage-project-dropdown d-flex align-items-center column-gap-2">
                {/* project dropdown  */}
                <button className="btn-edit" onClick={handleToggleDropdown}>
                  {currentProject?.title}
                  {isDropdownVisible ? (
                    <FaAngleUp className="m-0" />
                  ) : (
                    <FaAngleDown className="m-0" />
                  )}
                </button>

                {isDropdownVisible && (
                  <ul className="dropdown-list">
                    {allProjects?.map((project, index) => (
                      <li
                        key={index}
                        className="dropdown-item text-capitalize"
                        onClick={() => handleSelectProject(project?._id)}
                      >
                        {project?.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            {/* filter Col*/}
            <div className="col-lg-6 p-0">
              <Row className="align-items-center">
                <Col lg={4}>
                  <p className="m-0 sort-by mp-sort-by-txt"> Sort By:&nbsp;</p>
                </Col>

                <Col lg={8}>
                  {/* user filter  */}
                  <div className="manage-project-btns-wrapper align-items-center">
                    <DropDownButton
                      label="User"
                      options={employeeOptions}
                      onSelect={handleUserFilter}
                      selected={getEmployeeIdAndName(selectedUser)?.name} // Pass selected value
                    />

                    {/* priority filter  */}
                    <DropDownButton
                      label="Priority"
                      options={priorityOptions}
                      onSelect={handlePriorityFilter}
                      selected={selectedPriority} // Pass selected value
                    />

                    {/* status filter  */}
                    <DropDownButton
                      label="Status"
                      options={statusOptions}
                      onSelect={handleStatusFilter}
                      selected={selectedStatus} // Pass selected value
                    />

                    {/* resetFilters button  */}
                    <CommonButton
                      onClickAction={resetFilters}
                      btnClass="btn-edit new-small-btn"
                      btnName="Reset Filters"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  {/* add task and save  */}
                  <div className="add-save-task-btn-wrapper pt-20 d-flex justify-content-end column-gap-2">
                    <CommonButton
                      btnName={
                        <>
                          Add Task &nbsp;
                          <IoIosAddCircle />
                        </>
                      }
                      btnClass="btn-edit new-icon-btn"
                      onClickAction={handleAddTask}
                    />
                    {isEligibleForSave ? (
                      <CommonButton
                        btnName={
                          <>
                            Save &nbsp;
                            <IoSave />
                          </>
                        }
                        btnClass="btn-edit new-icon-btn"
                        onClickAction={handleSaveTasks}
                      />
                    ) : (
                      <CommonButton
                        btnName={
                          <>
                            Save &nbsp;
                            <IoSave />
                          </>
                        }
                        btnClass="btn-edit new-icon-btn not-clickable"
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {/* </Col> */}
          </Row>
        </Container>
      </div>

      {/* <div className="project-tasks-management"> */}
      <div className="project-ttask-wraapper custom-padding">
        <div className="project-tasks-management">
          {/* new task table  */}
          <NewTaskTable
            tasks={tasks}
            handleInputChange={handleInputChange}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            customTtClass="customTt"
            currentProject={currentProject?.title}
            teams={teams}
            getEmployeeIdAndName={getEmployeeIdAndName}
            projectLeads={currentProject?.projectLeads}
          />
          {/* <div>
          <table className="tasks-table nt-table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Task Summary</th>
                <th>Task Deadline</th>
                <th>Task Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks?.map((task, index) => (
                <tr key={task.id}>
                  <td>{index + 1}</td>
                  <td>
                    {task?.isOnEditMode ? (
                      <input
                        type="text"
                        name="summary"
                        value={task.summary}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    ) : (
                      task?.summary
                    )}
                  </td>
                  <td>
                    {task?.isOnEditMode ? (
                      <input
                        type="date"
                        name="endDate"
                        value={task.endDate}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    ) : (
                      task?.endDate
                    )}
                  </td>
                  <td>
                    {task?.isOnEditMode ? (
                      <select
                        name="status"
                        value={task.status}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option selected disabled >Select status</option>
                        <option value="completed">Completed</option>
                        <option value="inProgress">In Progress</option>
                        <option value="onHold">On Hold</option>
                      </select>
                    ) : (
                      task?.status
                    )}
                  </td>
                  <td>
                    <FaTrash
                      onClick={() => handleDeleteClick(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        </div>
        <Row>
          <Col lg={12}>
            {/* add task and save  */}
            <div className="add-save-task-btn-wrapper pt-20 d-flex justify-content-center column-gap-2">
              <CommonButton
                btnName={"Add Task"}
                btnClass="btn-edit new-icon-btn"
                onClickAction={handleAddTask}
              />
              {isEligibleForSave ? (
                <CommonButton
                  btnName={"Save Changes"}
                  btnClass="btn-edit new-icon-btn"
                  onClickAction={handleSaveTasks}
                />
              ) : (
                <CommonButton
                  btnName={"Save Changes"}
                  btnClass="btn-edit new-icon-btn not-clickable"
                />
              )}
            </div>
          </Col>
        </Row>
      </div >
      {isLoaderActive && <MiniLoader />
      }
      {
        isPopupActive && (
          <Popup status={popupStatus} message={popupText} hidePopup={hidePopup} />
        )
      }
    </>
  );
};

export default ManageProject;
