import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { serverRequestType } from "../../../../config";
import { clientSchema } from "../../../../formSchema";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import Popup from "../../../../common-components/popup/Popup";
import { userRequest } from "../../../../requestMethod";
import CommonButton from "../../../../common-components/button/CommonButton";

const EditClient = () => {
  const { id: clientId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const isUserFetching = useSelector((state) => state.client.isFetching);
  const state = useSelector((state) => state);
  const currentClient = useSelector((state) =>
    state.client?.clients?.find((client) => client._id === clientId)
  );


  // State for form values
  // const [formValues, setFormValues] = useState({
  //   name: "",
  //   brandName: "",
  //   email: "",
  //   phone: "",
  //   clientStatus: "",
  //   clientServices: [],  // Array to track selected services
  //   poc: [],  // Array to store POC data for each selected service
  //   isUserClient: true
  // });

  const [formValues, setFormValues] = useState(currentClient);

  useEffect(() => {
    setFormValues(currentClient);
  }, [currentClient]);


  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Data has been added");

  const handleToggle = () => {
    setIsDropDownVisible(!isDropDownVisible);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox selections (services)
      // setFormValues((prev) => ({
      //   ...prev,
      //   clientServices: checked
      //     ? [...prev.clientServices, name]  // Add service if checked
      //     : prev.clientServices.filter((service) => service !== name),  // Remove service if unchecked

      // }));

      setFormValues((prev) => {
        const updatedClientServices = checked
          ? [...prev.clientServices, name] // Add service if checked
          : prev.clientServices.filter((service) => service !== name); // Remove service if unchecked

        // If the service is unchecked, remove the corresponding POC data
        const updatedPoc = checked
          ? prev.poc // If adding a new service, keep existing POCs
          : prev.poc.filter((poc, index) =>
              updatedClientServices.includes(prev.clientServices[index])
            );

        return {
          ...prev,
          clientServices: updatedClientServices,
          poc: updatedPoc,
        };
      });
    } else {
      // Handle other inputs
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle POC input changes
  // const handlePocChange = (e, service, field, index) => {
  //   const { value } = e.target;

  //   setFormValues((prev) => {
  //     const updatedPoc = [...prev.poc];

  //     if (!updatedPoc[index]) {
  //       updatedPoc[index] = { name: "", contact: "", team: formValues?.clientServices[index] };
  //     }

  //     updatedPoc[index][field] = value;

  //     return {
  //       ...prev,
  //       poc: updatedPoc,
  //     };
  //   });
  // };
  const handlePocChange = (e, service, field, index) => {
    const { value } = e.target;

    setFormValues((prev) => {
      // Ensure 'poc' is initialized as an array if not already
      const updatedPoc = [...(prev.poc || [])];

      // Initialize the POC object at the specified index if it doesn't exist
      if (!updatedPoc[index]) {
        updatedPoc[index] = { name: "", contact: "", email: "", department: service };
      }

      // Create a new object for the POC to avoid direct mutation
      updatedPoc[index] = {
        ...updatedPoc[index],
        [field]: value,
      };

      return {
        ...prev,
        poc: updatedPoc,
      };
    });
  };

  const submitData = async (data) => {
    try {
      const res = await userRequest.put(`/client/${clientId}`, data);
      if (res?.status === 200) {
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res?.data?.message);
        // setValues(schema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}))
      }
    } catch (error) {
      setIsLoaderActive(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.message ? error?.response?.message : error?.response?.data);
    }
  };

  const handleSubmit = () => {
    submitData(formValues);
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };


  useEffect(() => {
    setInitialValues(currentClient);
  }, [currentClient]);

  if (isUserFetching) {
    // return <h1>Fetching</h1>;
    return <MiniLoader />;
  }

  return (
    // <>
    // {
    initialValues ? (
      <>
        {/* breadcrumb  */}
        <Breadcrumb mainTitle="Update Details" />
        <div className="add-emp-form-wrapper custom-padding">
          <Container>
            <Row>
              {/* Client Name */}
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    placeholder="Enter client name"
                    type="text"
                  />
                </Form.Group>
              </Col>

              {/* Brand Name */}
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    name="brandName"
                    value={formValues.brandName}
                    onChange={handleChange}
                    placeholder="Enter brand name"
                    type="text"
                  />
                </Form.Group>
              </Col>

              {/* Email */}
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    placeholder="Enter client email"
                    type="text"
                  />
                </Form.Group>
              </Col>

              {/* Phone */}
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    name="phone"
                    value={formValues.phone}
                    onChange={handleChange}
                    placeholder="Enter client phone number"
                    type="text"
                  />
                </Form.Group>
              </Col>

              {/* Client Status */}
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Client Status</Form.Label>
                  <Form.Select
                    className="text-capitalize cal-form-dropdown"
                    name="clientStatus"
                    value={formValues.clientStatus}
                    aria-label="Default select example"
                    onChange={handleChange}
                  >
                    <option value="" disabled={true}>
                      Select status
                    </option>
                    <option className="text-capitalize" value="active">
                      active
                    </option>
                    <option className="text-capitalize" value="former">
                      former
                    </option>
                    <option className="text-capitalize" value="maintenance">
                      maintenance
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* Client Services */}
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Client Services</Form.Label>
                  <div className="select-element-wrapper cal-form-dropdown">
                    <p onClick={handleToggle}>Select any service</p>
                    <div
                      className={`select-options-wrapper`}
                      style={{ height: isDropDownVisible ? "150px" : "0px" }}
                    >
                      <div
                        className={`select-options-inner-wrapper ${
                          isDropDownVisible ? "visible" : ""
                        }`}
                      >
                        <ul>
                          <li>
                            <input
                              name="Website Development"
                              checked={formValues.clientServices.includes(
                                "Website Development"
                              )}
                              onChange={handleChange}
                              type="checkbox"
                            />
                            Website Development
                          </li>
                          <li>
                            <input
                              name="Performance Marketing"
                              checked={formValues.clientServices.includes(
                                "Performance Marketing"
                              )}
                              onChange={handleChange}
                              type="checkbox"
                            />
                            Performance Marketing
                          </li>
                          <li>
                            <input
                              name="Brand Marketing"
                              checked={formValues.clientServices.includes(
                                "Brand Marketing"
                              )}
                              onChange={handleChange}
                              type="checkbox"
                            />
                            Brand Marketing
                          </li>
                          <li>
                            <input
                              name="Graphics"
                              checked={formValues.clientServices.includes(
                                "Graphics"
                              )}
                              onChange={handleChange}
                              type="checkbox"
                            />
                            Graphics
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Col>

              {/* Dynamically Generated POC Fields */}
              {formValues.clientServices.map((service, index) => (
                <Col sm={12} key={service}>
                  <h5>{`POC for ${service}`}</h5>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Point of Contact Name</Form.Label>
                        <Form.Control
                          name={`pocName-${service}`}
                          value={formValues.poc[index]?.name || ""}
                          onChange={(e) =>
                            handlePocChange(e, service, "name", index)
                          }
                          placeholder="Enter contact name"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          name={`pocContact-${service}`}
                          value={formValues.poc[index]?.contact || ""}
                          onChange={(e) =>
                            handlePocChange(e, service, "contact", index)
                          }
                          placeholder="Enter contact number"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name={`pocEmail-${service}`}
                          value={formValues.poc[index]?.email || ""}
                          onChange={(e) =>
                            handlePocChange(e, service, "email", index)
                          }
                          placeholder="Enter email"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              ))}

              <Col sm={12}>
                <CommonButton
                  btnName={"Submit"}
                  btnClass="common-btn btn-edit"
                  onClickAction={handleSubmit}
                />
              </Col>
            </Row>
          </Container>
        </div>
        {isLoaderActive && <MiniLoader />}
        {isPopupActive && (
          <Popup
            status={popupStatus}
            message={popupText}
            hidePopup={hidePopup}
          />
        )}
      </>
    ) : (
      <h1>Loading....</h1>
    )
    // }

    // </>
  );
};

export default EditClient;
