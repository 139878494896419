import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import CommonButton from "../button/CommonButton";
import MiniLoader from "../miniLoader/MiniLoader";
import { userRequest } from "../../requestMethod";
import Popup from "../popup/Popup";
import { serverRequestType, validate } from "../../config";
import "./DynamicForm.css";
import DynamicFieldsBasedOnCheckbox from "./form-components/DynamicFieldsBasedOnCheckbox/DynamicFieldsBasedOnCheckbox";

const DynamicForm = ({
  schema,
  httpMethod,
  apiEndPoint,
  initialValues = {},
  buttonName,
  userType,
}) => {
  const [values, setValues] = useState(
    schema?.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: initialValues[field?.name] || "",
      }),
      {}
    )
  );

  useEffect(() => {
    if (Object.keys(initialValues)?.length > 0) {
      setValues(
        schema?.reduce(
          (acc, field) => ({
            ...acc,
            [field.name]: initialValues[field?.name] || "",
          }),
          {}
        )
      );
    }
  }, [initialValues, schema]);

  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Data has been added");
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const defaultInputFiedColumnWidth = 4;

  // const handleToggle = () => {
  //   setIsVisible(!isVisible);
  // };

  const [visibilityStates, setVisibilityStates] = useState(
    schema?.map(() => false)
  );

  const handleToggle = (index) => {
    setVisibilityStates((prevVisibilityStates) =>
      prevVisibilityStates?.map((isVisible, i) =>
        i === index ? !isVisible : isVisible
      )
    );
  };

  // const [searchTerm, setSearchTerm] = useState('');

  // const handleSearchChange = (e) => {
  //   // Handle the change of the search input
  //   setSearchTerm(e.target.value);
  // };

  // Filter products based on the search term
  // const filteredSearch = countriesListWithCode?.filter(country =>
  //   country.name.toLowerCase().includes(searchTerm?.toLowerCase())
  // );

  const [searchTerms, setSearchTerms] = useState({}); // Object to store search terms for each dropdown

  // Handle the change of the search input for a specific dropdown
  const handleSearchChange = (e, index) => {
    const { value } = e.target;
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [index]: value, // Set search term for specific dropdown
    }));
  };

  // Function to filter the options based on the search term
  const getFilteredOptions = (options, searchTerm, field) => {
    return options?.filter((option) =>
      option[field?.valueToBeRender]
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   // Handle checkbox changes
  //   if (type === 'checkbox') {
  //     setValues((prevValues) => {
  //       const currentValues = prevValues[name] || [];
  //       if (checked) {
  //         // Add value to the array if checked
  //         return { ...prevValues, [name]: [...currentValues, value] };
  //       } else {
  //         // Remove value from the array if unchecked
  //         return { ...prevValues, [name]: currentValues?.filter((v) => v !== value) };
  //       }
  //     });
  //   } else {
  //     // Handle other input types
  //     setValues({ ...values, [name]: value });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setValues((prevValues) => {
      const updatedValues = { ...prevValues };

      // Handle checkbox changes
      if (type === "checkbox") {
        const currentValues = prevValues[name] || [];
        if (checked) {
          updatedValues[name] = [...currentValues, value];
        } else {
          updatedValues[name] = currentValues.filter((v) => v !== value);
        }
      } else {
        // Handle other input types, including dynamic nested inputs
        const nameParts = name.split("[")?.map((part) => part.replace("]", ""));

        if (nameParts.length > 1) {
          let temp = updatedValues;
          nameParts.forEach((part, index) => {
            if (index === nameParts.length - 1) {
              temp[part] = value;
            } else {
              temp[part] = temp[part] || {};
              temp = temp[part];
            }
          });
        } else {
          updatedValues[name] = value;
        }
      }

      return updatedValues;
    });
  };

  const submitData = async (values) => {
    // if(values?.employeeLevel === 'employee'){
    //     setValues({ ...values, isUserEmployee: true, isUserDepartmentHead: false });
    // }

    // if(values?.employeeLevel === 'team-head'){
    //     setValues({ ...values, isUserDepartmentHead: true,isUserEmployee: false,});
    // }

    // Directly create a new object with the required fields updated
    let updatedValues = { ...values };

    if (values?.employeeLevel === "employee") {
      updatedValues = {
        ...updatedValues,
        isUserEmployee: true,
        isUserDepartmentHead: false,
      };
    }
    if (values?.employeeLevel === "team-head") {
      updatedValues = {
        ...updatedValues,
        isUserDepartmentHead: true,
        isUserEmployee: true,
      };
    }

    if (userType === "client") {
      updatedValues = {
        ...updatedValues,
        isUserClient: true,
      };
    }

    setIsLoaderActive(true);
    try {
      const res = await userRequest[httpMethod](
        `${apiEndPoint}`,
        updatedValues
      );
      // let res;
      // if (httpMethod === serverRequestType.PUT) {
      //     // res = await userRequest.put(apiEndPoint, values);
      //     res = await userRequest[httpMethod](apiEndPoint);
      // } else {
      //     res = await userRequest[httpMethod](apiEndPoint, values);
      // }
      if (res?.status === 200) {
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res?.data?.message);
        setValues(schema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}))
      }
    } catch (error) {
      console.log("coming error ===>", error)
      setIsLoaderActive(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data.message ? error?.response?.data.message : error?.response?.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values, schema);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      submitData(values);
    }
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  return (
    <>
      <Container>
        <Form className="form-main-container">
          <Row>
            {schema?.map((field, index) => {
              return (
                <>
                  {/* <Col sm={4}> */}
                  {field.type === "dropdown" ? (
                    <Col
                      sm={
                        field.fieldWidth
                          ? field.fieldWidth
                          : defaultInputFiedColumnWidth
                      }
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="form.ControlInput1"
                      >
                        <Form.Label>{field.label}</Form.Label>
                        {field?.multiple === true ? (
                          <>
                            <div className="select-element-wrapper cal-form-dropdown">
                              <p onClick={() => handleToggle(index)}>
                                Select any option
                              </p>
                              <div
                                className={`select-options-wrapper`}
                                style={{
                                  height: visibilityStates[index]
                                    ? "150px"
                                    : "0px",
                                }}
                              >
                                {/* <div className={`select-options-wrapper ${isVisible ? 'visible' : ''}`}> */}
                                {/* <div className={`select-options-inner-wrapper ${isVisible ? 'visible' : ''}`}> */}
                                <div
                                  className={`select-options-inner-wrapper ${visibilityStates[index] ? "visible" : ""
                                    }`}
                                >
                                  <ul>
                                    {field.options?.map((option) => {
                                      // const propertyName = field.valueToBeRender;
                                      return (
                                        <li>
                                          <input
                                            name={field.name}
                                            value={option}
                                            onChange={handleChange}
                                            type="checkbox"
                                          />
                                          {option}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Form.Select
                            className="text-capitalize cal-form-dropdown"
                            name={field.name}
                            value={values[field.name]}
                            aria-label="Default select example"
                            onChange={handleChange}
                            multiple={
                              field?.multiple === true ? "multiple" : ""
                            }
                          >
                            <option value="" disabled={true}>
                              Select any option
                            </option>
                            {field.options?.map((option) => {
                              return (
                                <option
                                  className="text-capitalize"
                                  value={option}
                                >
                                  {option}
                                </option>
                              );
                            })}
                          </Form.Select>
                        )}
                      </Form.Group>
                      {errors[field.name] && (
                        <div className="error-msg">{errors[field.name]}</div>
                      )}
                    </Col>
                  ) : field.type === "dropdownWithArrayObjectOption" ? (
                    <Col
                      sm={
                        field.fieldWidth
                          ? field.fieldWidth
                          : defaultInputFiedColumnWidth
                      }
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="form.ControlInput1"
                      >
                        <Form.Label>{field.label}</Form.Label>
                        {field?.multiple === true ? (
                          <>
                            <div className="select-element-wrapper cal-form-dropdown">
                              <p
                                onClick={() => handleToggle(index)}
                                className="m-0"
                              >
                                {values[field.name].length > 0 ? (
                                  <span>
                                    {values[field.name].length} option selected
                                  </span>
                                ) : (
                                  <span>Select any option</span>
                                )}
                              </p>
                              <div
                                className={`select-options-wrapper`}
                                style={{
                                  height: visibilityStates[index]
                                    ? "150px"
                                    : "0px",
                                }}
                              >
                                {/* <div className={`select-options-wrapper ${isVisible ? 'visible' : ''}`}> */}
                                {/* <div className={`select-options-inner-wrapper ${isVisible ? 'visible' : ''}`}> */}
                                <div
                                  className={`select-options-inner-wrapper ${visibilityStates[index] ? "visible" : ""
                                    }`}
                                >
                                  <ul>
                                    {/* Search input */}
                                    <input
                                      type="text"
                                      className="checkout-search-bar"
                                      placeholder="Search here..."
                                      value={searchTerms[index] || ""} // Search term for this dropdown
                                      onChange={(e) =>
                                        handleSearchChange(e, index)
                                      } // Update search term
                                    />
                                    {/* Filter and render the options */}
                                    {getFilteredOptions(
                                      field.options,
                                      searchTerms[index] || "",
                                      field
                                    )?.map((option) => {
                                      return (
                                        <li
                                          key={option._id}
                                          className="text-capitalize"
                                        >
                                          <input
                                            name={field.name}
                                            type="checkbox"
                                            value={option._id}
                                            checked={values[
                                              field.name
                                            ]?.includes(option._id)}
                                            onChange={handleChange}
                                          />
                                          {option[field?.valueToBeRender]}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Form.Select
                            className="text-capitalize cal-form-dropdown mulitple-select-dropdown"
                            name={field.name}
                            value={values[field.name]}
                            aria-label="Default select example"
                            onChange={handleChange}
                            multiple={
                              field?.multiple === true ? "multiple" : ""
                            }
                          >
                            <option value="" disabled={true}>
                              Select any option
                            </option>
                            {
                              field.showNullValue && (
                                <option
                                  className="text-capitalize"
                                  value={field.valueToBeStoredForNullValue ? field.valueToBeStoredForNullValue : null}
                                >
                                  {field.nullValueTitle}
                                </option>
                              )
                            }
                            {field.options?.map((option) => {
                              const propertyName = field.valueToBeRender;
                              return (
                                <option
                                  className="text-capitalize"
                                  value={field.valueToBeStored ? field.valueToBeStored : option._id}
                                >
                                  {option[propertyName]}
                                </option>
                              );
                            })}
                          </Form.Select>
                        )}
                      </Form.Group>
                      {errors[field.name] && (
                        <div className="error-msg">{errors[field.name]}</div>
                      )}
                    </Col>
                  ) : field.type === "conditionalDropdown" ? (
                    <Col
                      sm={
                        field.fieldWidth
                          ? field.fieldWidth
                          : defaultInputFiedColumnWidth
                      }
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="form.ControlInput1"
                      >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Select
                          name={field.name}
                          className="text-capitalize cal-form-dropdown"
                          value={values[field.name]}
                          aria-label="Default select example"
                          onChange={handleChange}
                        >
                          <option value="" disabled={true}>
                            Select any option
                          </option>
                          {field.options?.map((option) => {
                            if (option.team == values.team) {
                              return (
                                <>
                                  {option.designations?.map((designation) => {
                                    return (
                                      <option
                                        className="text-capitalize"
                                        value={designation}
                                      >
                                        {designation}
                                      </option>
                                    );
                                  })}
                                </>
                              );
                            }
                          })}
                        </Form.Select>
                      </Form.Group>
                      {errors[field.name] && (
                        <div className="error-msg">{errors[field.name]}</div>
                      )}
                    </Col>
                  ) : field.type === "inputFieldsBasedOnSelectedCheckbox" ? (
                    <>
                      <Col sm={12}>
                        <DynamicFieldsBasedOnCheckbox
                          label={field.label}
                          checkBoxDependencyName={field.checkBoxDependencyName}
                          inputFieldConfig={field.inputFields}
                          numberOfInputFields={1}
                          values={values}
                          onChangeFunction={handleChange}
                          checkBoxDependencyLabel={
                            field.checkBoxDependencyLabel
                          }
                        />
                        {errors[field.name] && (
                          <div className="error-msg">{errors[field.name]}</div>
                        )}
                      </Col>
                    </>
                  ) : field.type === "textarea" ? (
                    <Col
                      sm={
                        field.fieldWidth
                          ? field.fieldWidth
                          : defaultInputFiedColumnWidth
                      }
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="form.ControlInput1"
                      >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                          name={field.name}
                          value={values[field.name]}
                          onChange={handleChange}
                          as="textarea"
                          type={field.type}
                          placeholder={field.placeholder}
                        />
                      </Form.Group>
                      {errors[field.name] && (
                        <div className="error-msg">{errors[field.name]}</div>
                      )}
                    </Col>
                  ) : (
                    <Col
                      sm={
                        field.fieldWidth
                          ? field.fieldWidth
                          : defaultInputFiedColumnWidth
                      }
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="form.ControlInput1"
                      >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                          name={field.name}
                          value={values[field.name]}
                          onChange={handleChange}
                          type={field.type}
                          placeholder={field.placeholder}
                        />
                      </Form.Group>
                      {errors[field.name] && (
                        <div className="error-msg">{errors[field.name]}</div>
                      )}
                    </Col>
                  )}
                  {/* {errors[field.name] && (
                    <div className="error-msg">{errors[field.name]}</div>
                  )} */}
                  {/* </Col > */}
                </>
              );
            })}
            {schema ? (
              <Col sm={12}>
                <CommonButton
                  btnName={buttonName ? buttonName : "Submit"}
                  btnClass="common-btn btn-edit"
                  onClickAction={handleSubmit}
                />
              </Col>
            ) : (
              <h4 className="text-center">
                Kindly pass some schema to show the form
              </h4>
            )}
          </Row>
        </Form>
      </Container>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup status={popupStatus} message={popupText} hidePopup={hidePopup} />
      )}
    </>
  );
};

export default DynamicForm;
