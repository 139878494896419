import { PiVideoConferenceLight } from "react-icons/pi";
import { FaRegSquareCheck } from "react-icons/fa6";
import { BsListTask, BsPersonWorkspace } from "react-icons/bs";
import { GoPersonAdd } from "react-icons/go";
import { TbStatusChange } from "react-icons/tb";
import {
  MdOutlineWorkOff,
  MdOutlinePlaylistRemove,
  MdOutlinePersonAddAlt,
} from "react-icons/md";
import { LuTimer } from "react-icons/lu";
import { CgFolderAdd, CgFolderRemove } from "react-icons/cg";
import { RiFolderCheckLine } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";


export const departmentName = [
  "Web Department",
  // "Brand Marketing",
  // "Performance Marketing",
  // "Content Department",
  "Digital Department",
  "Graphic Department",
  "Account Department",
];

// export const designationName = ['Trainee', 'Executve','Frontend Developer', 'Backend Developer',];
export const designationName = [
  {
    department: "Web Department",
    designations: [
      "Trainee",
      "Full Stack Developer",
      "Frontend Developer",
      "Backend Developer",
    ],
  },
  {
    department: "Digital Department",
    designations: ["Trainee", "Team Lead", "Executive"],
  },
  // {
  //   department: "Brand Marketing",
  //   designations: ["Trainee", "Team Lead", "Executive"],
  // },
  // {
  //   department: "Performance Marketing",
  //   designations: ["Trainee", "Team Lead", "Executive"],
  // },
  // {
  //   department: "Content Department",
  //   designations: ["Trainee", "Team Lead", "Executive"],
  // },
  {
    department: "Graphic Department",
    designations: [
      "Trainee",
      "Team Lead",
      "Graphic Designer",
      "Sr. Graphic Designer",
    ],
  },
  {
    department: "Account Department",
    designations: ["Trainee", "Accounting Manager", "Executive"],
  },
];

export const newEmployeeTypes = ["intern", "full time"];
export const oldEmployeeTypes = [
  "intern",
  "full time",
  "former",
  "notice period",
];
// export const employeeLevel = ['employee', 'team-head',];
export const employeeLevel = ["employee", "team head"];
export const clientStatus = ["active", "former", "maintenance"];

export const clientServices = [
  "website development",
  "performance marketing",
  "brand marketing",
  "graphics",
  "content management",
];
// export const clientStatus = {
//   ACTIVE: "active",
//   FORMER: "former",
//   MAINTENANCE: "maintenance"
// }

export const intentOptionForUserCalenderForm = [
  "Brand",
  "Knowledge Sharing",
  "Entertain",
  "Inspire",
  "Convince",
  "Sales",
  "Engage",
  "Special Days",
];

export const typeOfPostOptionsForUserCalenderForm = [
  "Single Image",
  "Carousel",
  "Reel",
  "Video",
  "Text",
  "Poll",
];

export const statusOptionsForUserCalenderForm = [
  "Rejected",
  "Delayed",
  "Posted",
  "Scheduled",
  "Approved",
  "Under Process",
];

export const serverRequestType = {
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const priority = ["High", "Med", "Low"];
export const status = [
  "Suspended",
  "In Progress",
  "Delayed",
  "Completed",
  "Inactive",
  "Paused",
];
// export const status = {
//   ACTIVE: "active",
//   FORMER: "former",
//   MAINTENANCE: "maintenance",
//   OPEN: "open",
//   INPROGRESS: "in-progress",
//   CLOSED: "closed",
//   COMPLETED: "completed"
// }
export const leaveType = ["Full Day Leave", "Half Day Leave", "Work From Home"];

export const validate = (values, schema) => {
  const errors = {};

  schema?.forEach((field) => {
    if (
      field?.validation?.includes("required") &&
      (!values[field.name] || values[field.name].length < 1)
    ) {
      errors[field.name] = "This field is required";
    }
    if (field?.validation?.includes("email") && values[field.name]) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(values[field.name])) {
        errors[field.name] = "Invalid email address";
      }
    }
  });

  return errors;
};

export const deepEqualCheckForTwoArray = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  for (let i = 0; i < arr1?.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
  }
  return true;
};

export const getStatus = (statusType, status) => {
  if (statusType === "task") {
    switch (status) {
      case "in progress":
        return "In Progress";
      case "completed":
        return "Completed";
      case "suspended":
        return "Suspended";
      case "delayed":
        return "Delayed";
      case "inactive":
        return "Inactive";
      case "paused":
        return "Paused";
      default:
        return "Unassigned";
    }
  }
  return "Invalid Status Type";
};

export const changeDateFormat = (date) => {
  // console.log("coming data to change format ==>", date)
  if(date){
    const fetchedDate = new Date(date);
    const formattedDate = fetchedDate.toLocaleDateString();
    return formattedDate;
  }
  
};

// Function to extract unique departments
export const getUniqueDepartments = (employees) => {
  const departmentSet = new Set();
  employees?.forEach((employee) => {
    if (employee?.department) {
      departmentSet?.add(employee.department);
    }
  });
  return Array?.from(departmentSet)?.map((department) => ({
    label: department,
    value: department,
  }));
};

// Function to extract unique employee statuses
export const getUniqueEmployeeStatuses = (employees) => {
  const statusSet = new Set();
  employees?.forEach((employee) => {
    if (employee?.employeeStatus) {
      statusSet.add(employee.employeeStatus);
    }
  });
  return Array?.from(statusSet)?.map((status) => ({
    label: status,
    value: status,
  }));
};

// Function to extract unique assignedTaskUser
export const getUniqueAssignedTaskUser = (tasks) => {
  const userSet = new Set();
  tasks?.forEach((task) => {
    if (task?.employeeStatus) {
      userSet.add({ employeeId: task.employeeStatus });
      userSet.add({ employeeId: task.employeeStatus });
    }
  });
  return Array?.from(userSet)?.map((status) => ({
    label: status,
    value: status,
  }));
};

export const isMeetingLive = (startTime, endTime) => {
  const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
  return currentTime >= startTime && currentTime <= endTime;
};

export const getColorForPriority = (priority) => {
  switch (priority) {
    case "high":
      return "var(--Red)";

    case "mid":
      return "var(--Orange)";

    case "low":
      return "var(--Green)";

    default:
      return "#FFFFFF";
  }
};

export const getColorForStatus = (status) => {
  switch (status) {
    case "pending":
      return "#EE534F";

    case "in progress":
      return "#FEB056";

    case "completed":
      return "#A2CD48";

    case "delayed":
      return "var(--Red)";

    default:
      return "#FFFFFF";
  }
};

export const getBGColorForTaskStatus = (status) => {
  switch (status) {
    case "inactive":
      return "var(--Grey)";

    case "in progress":
      return "#FEB056";

    case "completed":
      return "var(--Green)";

    case "delayed":
      return "var(--Red)";

    case "paused":
      return "var(--DAqua)";

    case "suspended":
      return "#000000";

    default:
      return "#FFFFFF";
  }
};

export const priorityOptions = [
  { label: "Low", value: "low" },
  { label: "Mid", value: "mid" },
  { label: "High", value: "high" },
];

export const statusOptions = [
  { label: "Inactive", value: "inactive" },
  { label: "In Progress", value: "in progress" },
  { label: "Delayed", value: "delayed" },
  { label: "Paused", value: "paused" },
  { label: "Completed", value: "completed" },
  { label: "Suspended", value: "suspended" },
];

export const PERMISSIONS = [
  'add_employee',
  'edit_employee',
  'delete_employee',
  'add_project',
  'edit_project',
  'delete_project',
  'add_client',
  'edit_client',
  'delete_client',
  'add_role',
  'edit_role',
  'delete_role',
];


export const hasPermission = (currentUser, requiredPermissions = []) => {
  if (!currentUser) return false;

  // Organisation owner has all permissions
  if (currentUser?.userType === "organisation-owner") return true;

  // Check if the user has any of the required permissions
  if (currentUser?.role?.status && currentUser?.role?.permissions) {
    return requiredPermissions?.some(permission =>
      currentUser?.role?.permissions?.includes(permission)
    );
  }

  return false; // Default to false if no permissions match
};

// export const notiContent = [
//   {
//     icon: <PiVideoConferenceLight />,
//     title: "New Meeting Created",
//     description: "Kritika just created a new meeting.",
//     time: "2 hrs ago",
//   },
//   {
//     icon: <FaRegSquareCheck />,
//     title: "Leave Approved",
//     description: "Your leave has been approved.",
//     time: "3 hrs ago",
//   },
//   {
//     icon: <BsListTask />,
//     title: "New Task Added",
//     description: "A new task has been assigned to you.",
//     time: "4 hrs ago",
//   },
//   {
//     icon: <BsPersonWorkspace />,
//     title: "Work From Home Applied",
//     description: "Vikas applied for a new WFH.",
//     time: "6 hrs ago",
//   },
//   {
//     icon: <MdOutlineWorkOff />,
//     title: "New Leave Applied",
//     description: "Moin khan applied for a leave.",
//     time: "8 hrs ago",
//   },
//   {
//     icon: <GoPersonAdd />,
//     title: "We have a New Joinee",
//     description: "Sourabh joined as a Graphic designer.",
//     time: "10 hrs ago",
//   },
//   {
//     icon: <BsListTask />,
//     title: "New Task Assigned",
//     description: "50 New tasks have been Assigned.",
//     time: "12 hrs ago",
//   },
//   {
//     icon: <MdOutlinePlaylistRemove />,
//     title: "Task Removed",
//     description: "A task has been Removed",
//     time: "14 hrs ago",
//   },
//   {
//     icon: <LuTimer />,
//     title: "Task Delayed",
//     description: "Your Task is getting delayed.",
//     time: "16 hrs ago",
//   },
//   {
//     icon: <MdOutlinePersonAddAlt />,
//     title: "New Client",
//     description: "We have a new Client Onboard - PCJS",
//     time: "17 hrs ago",
//   },
//   {
//     icon: <CgFolderAdd />,
//     title: "New Project Assigned",
//     description:
//       "You have been Deployed on Project (PCJS) under client (Mohit Jain)",
//     time: "17 hrs ago",
//   },
//   {
//     icon: <CgFolderRemove />,
//     title: "Project Terminted",
//     description: "You are no longer part of the project (PCJS).",
//     time: "17 hrs ago",
//   },
//   {
//     icon: <RiFolderCheckLine />,
//     title: "Project Completed",
//     description:
//       "Congratulations! Your Project (PCJS) Has been completed sucessfully.",
//     time: "17 hrs ago",
//   },
//   {
//     icon: <LuTimer />,
//     title: "Task Deadline Updated",
//     description: "Your Task Deadline has been Updated.",
//     time: "17 hrs ago",
//   },
//   {
//     icon: <LuTimer />,
//     title: "Task Deadline Closed",
//     description: "Your Task Deadline is closed now",
//     time: "17 hrs ago",
//   },
// ];



export const notiContent = [
  {
    icon: <PiVideoConferenceLight />,
    title: "New Meeting Created",
    description: "Kritika just created a new meeting.",
    time: "2 hrs ago",
    type: "new-meeting",
  },
  {
    icon: <MdOutlineWorkOff />,
    title: "New Leave Applied",
    description: "Moin khan applied for a leave.",
    time: "8 hrs ago",
    type: "leave-applied",
  },
  {
    icon: <FaRegSquareCheck />,
    title: "Leave Approved",
    description: "Your leave has been approved.",
    time: "3 hrs ago",
    type: "leave-approved",
  },
  {
    icon: <BsPersonWorkspace />,
    title: "Work From Home Applied",
    description: "Vikas applied for a new WFH.",
    time: "6 hrs ago",
    type: "wfh-applied",
  },
  {
    icon: <GoPersonAdd />,
    title: "We have a New Joinee",
    description: "Sourabh joined as a Graphic designer.",
    time: "10 hrs ago",
    type: "new-joinee",
  },
  {
    icon: <BsListTask />,
    title: "New Task Added",
    description: "A new task has been assigned to you.",
    time: "4 hrs ago",
    type: "new-task",
  },
  {
    icon: <BsListTask />,
    title: "New Task Assigned",
    description: "50 New tasks have been Assigned.",
    time: "12 hrs ago",
    type: "new-task-assigned",
  },
  {
    icon: <MdOutlinePlaylistRemove />,
    title: "Task Removed",
    description: "A task has been Removed",
    time: "14 hrs ago",
    type: "task-removed",
  },
  {
    icon: <LuTimer />,
    title: "Task Delayed",
    description: "Your Task is getting delayed.",
    time: "16 hrs ago",
    type: "task-delayed",
  },
  {
    icon: <LuTimer />,
    title: "Task Deadline Updated",
    description: "Your Task Deadline has been Updated.",
    time: "17 hrs ago",
    type: "task-deadline-updated",
  },
  {
    icon: <LuTimer />,
    title: "Task Deadline Closed",
    description: "Your Task Deadline is closed now",
    time: "17 hrs ago",
    type: "task-deadline-closed",
  },
  {
    icon: <MdOutlinePersonAddAlt />,
    title: "New Client",
    description: "We have a new Client Onboard - PCJS",
    time: "17 hrs ago",
    type: "new-client",
  },
  {
    icon: <CgFolderAdd />,
    title: "New Project Assigned",
    description:
      "You have been Deployed on Project (PCJS) under client (Mohit Jain)",
    time: "17 hrs ago",
    type: "new-project-assigned",
  },
  {
    icon: <CgFolderRemove />,
    title: "Project Terminted",
    description: "You are no longer part of the project (PCJS).",
    time: "17 hrs ago",
    type: "project-terminated",
  },
  {
    icon: <RiFolderCheckLine />,
    title: "Project Completed",
    description:
      "Congratulations! Your Project (PCJS) Has been completed sucessfully.",
    time: "17 hrs ago",
    type: "project-completed",
  },
];



// Mapping of types to their respective icons
export const iconMap = {
  "new-meeting": <PiVideoConferenceLight />,
  "leave-applied": <MdOutlineWorkOff />,
  "leave-approved": <FaRegSquareCheck />,
  "leave-rejected": <MdOutlineCancel />,
  "wfh-applied": <BsPersonWorkspace />,
  "new-joinee": <GoPersonAdd />,
  "status-changed": <TbStatusChange />,
  "new-task": <BsListTask />,
  "task-assigned": <BsListTask />,
  "task-status-updated": <BsListTask />,
  "task-completed": <RiFolderCheckLine />,
  "task-removed": <MdOutlinePlaylistRemove />,
  "task-delayed": <LuTimer />,
  "task-deadline-updated": <LuTimer />,
  "task-deadline-closed": <LuTimer />,
  "new-client": <MdOutlinePersonAddAlt />,
  "project-assigned": <CgFolderAdd />,
  "project-terminated": <CgFolderRemove />,
  "project-completed": <RiFolderCheckLine />,
};


// Helper function to format time as "just now," "x min ago," "x hours ago," etc.
export const formatRelativeTime = (timestamp) => {
  const now = new Date();
  const diff = now.getTime() - new Date(timestamp).getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else {
    return 'just now';
  }
};