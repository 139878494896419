import "./Attendance.css";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import GeneralTable from "../../../common-components/gen-table/GeneralTable";
import { useEffect, useState } from "react";
import { changeDateFormat } from "../../../config";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAttendance,
  getAllAttendanceOfEmployee,
} from "../../../redux/apiCalls";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import "rsuite/dist/rsuite.min.css";
import "rsuite/DateRangePicker/styles/index.css";
import { endOfDay, isWithinInterval, parseISO, startOfDay } from "date-fns";
import { useParams } from "react-router-dom";

const Attendance = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([null, null]);
  const { id: employeeId } = useParams();

  useEffect(() => {
    if (employeeId) {
      getAllAttendanceOfEmployee(dispatch, employeeId);
    } else {
      getAllAttendance(dispatch);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   getAllAttendance(dispatch)
  // }, [dispatch])

  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const state = useSelector((state) => state);
  const { attendances, isFetching } = useSelector((state) => state.attendance);

  const attendanceTableHeader = [
    {
      headerName: "Date",
      valueToBeRender: "dateOfTheDay",
      renderFunction: (value, rowData) => {
        return changeDateFormat(value);
      },
    },
    {
      headerName: "Day",
      valueToBeRender: "nameOfTheDay",
    },
    {
      headerName: "In Time",
      valueToBeRender: "logInTime",
    },
    {
      headerName: "Out Time",
      valueToBeRender: "logOffTime",
    },
    {
      headerName: "Status",
      valueToBeRender: "status",
      renderFunction: (value) => {
        let statusColor = "";

        switch (value) {
          case "present":
            statusColor = "var(--Green)";
            break;
          case "half day":
            statusColor = "var(--Orange)";
            break;
          case "absent":
            statusColor = "var(--Red)";
            break;
          case "work from home":
            statusColor = "var(--violet)";
            break;
          default:
            statusColor = "var(--Grey)";
            break;
        }

        // return (
        //   <span
        //     style={{
        //       color: statusColor,
        //       textTransform: "capitalize",
        //     }}
        //   >
        //     {value}
        //   </span>
        // );
      },
    },
  ];

  if (isFetching) {
    return <MiniLoader />;
  }

  // filter attendance
  const filteredAttendence = attendances?.filter((record) => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) return true;

    const recordDate = parseISO(record.dateOfTheDay);

    return isWithinInterval(recordDate, {
      start: startOfDay(dateRange[0]),
      end: endOfDay(dateRange[1]),
    });
  });

  return (
    <>
      <Breadcrumb mainTitle="Attendance" />
      <div className="attendance-record-table custom-padding">
        <div className="attendence-data-filter pb-2">
          <DateRangePicker
            placeholder="Select Date Range"
            shouldDisableDate={(date) => isAfter(date, new Date())}
            onChange={setDateRange}
            character="&nbsp;-&nbsp;"
          />
        </div>

        <GeneralTable
          headers={attendanceTableHeader}
          tableData={filteredAttendence}
          rowsPerPage={10}
        />
      </div>
    </>
  );
};

export default Attendance;
