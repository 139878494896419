import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { SocketProvider } from "./common-components/socket-provider/socketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Get the current user ID from Redux state
// const currentUser = useSelector((state) => state.user.currentUser);


root.render(
  <Provider store={store}>
    <PersistGate loading="null" persistor={persistor}>
      <BrowserRouter>
        {/* Pass the user ID to the SocketProvider */}
        {/* <SocketProvider userId={currentUser?._id}> */}
          <App />
        {/* </SocketProvider>       */}
        </BrowserRouter>
    </PersistGate>
  </Provider>
);

// ServiceWorkerRegistration.register();