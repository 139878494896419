import "./App.css";
import "./styles.css";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "./pages/sign-in/SignIn";
import Header from "./common-components/header/Header";
import Footer from "./common-components/footer/Footer";
import HeaderNav from "./pages/emp-dashboard/sidebar/HeaderNav";
import EmpOverview from "./pages/emp-dashboard/emp-overview/EmpOverview";
import Employees from "./pages/emp-dashboard/employees/Employees";
import Services from "./pages/emp-dashboard/services/Services";
import AllReports from "./pages/reports/all-reports/AllReports";
import SocialMediaNos from "./pages/reports/reports/social-media-numbers/SocialMediaNos";
import AllPerformanceReports from "./pages/reports/reports/AllPerformanceReports";
import PerformanceMarketingStats from "./pages/reports/reports/performance-marketing-stats/PerformanceMarketingStats";
// import AllTasksTable from "./pages/emp-dashboard/all-tasks/AllTasksTable";
import AccountSetting from "./pages/emp-dashboard/employee-account/account-setting/AccountSetting";
import CalendarMain from "./pages/emp-dashboard/calender/main-calender/CalendarMain";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddEmployee from "./pages/employee/add-employee/AddEmployee";
import TokenExpirationInterceptor from "./common-components/token-expiration/TokenExpirationInterceptor";
import UpdateEmployee from "./pages/employee/update-employee.jsx/UpdateEmployee";
import { Container, Row, Col } from "react-bootstrap";
import AllClients from "./pages/emp-dashboard/clients/all-clients/AllClients";
import AddClient from "./pages/emp-dashboard/clients/add-client/AddClient";
import Departments from "./pages/emp-dashboard/departments/Departments";
import Tickets from "./pages/emp-dashboard/tickets/all-tickets/Tickets";
import CreateTicket from "./pages/emp-dashboard/tickets/create-ticket/CreateTicket";
import LeavesOverview from "./pages/emp-dashboard/leaves/leaves-overview/LeavesOverview";
import LeaveWFHForm from "./pages/emp-dashboard/leaves/leave-application-form/LeaveWFHForm";
import Meetings from "./pages/emp-dashboard/meetings/Meetings";
import EditClient from "./pages/emp-dashboard/clients/update-client/EditClient";
import AllProjects from "./pages/emp-dashboard/projects/all-projects/AllProjects";
import AddProject from "./pages/emp-dashboard/projects/add-project/AddProject";
import AddTask from "./pages/emp-dashboard/all-tasks/add-task/AddTask";
import EditProject from "./pages/emp-dashboard/projects/update-project/EditProject";
import AllTasksTable from "./pages/emp-dashboard/all-tasks/AllTasksTable";
import ManageProject from "./pages/emp-dashboard/all-tasks/project-tasks/ManageProject";
import Attendance from "./pages/emp-dashboard/attendance/Attendance";
import AddSMReport from "./pages/reports/reports/add-social-media-report/AddSMReport";
import GlobalNotification from "./pages/global-notification/GlobalNotification";
import UserCalendar from "./pages/emp-dashboard/calender/user-calendar/UserCalendar";
import NewMeeting from "./pages/meeting/NewMeeting";
import EditMeeting from "./pages/meeting/EditMeeting";
import AllNotifications from "./pages/all-notifications/AllNotifications";
import RolesTable from "./pages/roles/RolesTable";
import useCurrentUser from "./custom-hooks/useCurrentUser";
import { SocketProvider } from "./common-components/socket-provider/socketContext";
import FileNotFound from "./pages/404/FileNotFound";
import SignUp from "./pages/sign-up/SignUp";
import AllNotes from "./pages/emp-dashboard/notes/all-notes/AllNotes";
import NotesEditor from "./pages/emp-dashboard/notes/notes-editor/NotesEditor";

function App() {
  const [isUser, setIsUser] = useState(false);
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const state = useSelector((state) => state);
  const userDetail = useSelector((state) => state.loginUser.currentUser);

  const currentUser = useCurrentUser();

  console.log("cuurent user in app.js==>", currentUser);

  useEffect(() => {
    setIsUser(!!userDetail);
  }, [userDetail]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setMenuCollapsed(true);
      } else {
        setMenuCollapsed(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <TokenExpirationInterceptor />
      {/* <SocketProvider> */}
      <SocketProvider userId={currentUser?._id}>
        <div className="App">
          <Header />
          <div className="main-container-wrapper">
            <Container fluid className="p-0">
              <Row>
                <Col>
                  <div id="main-container" style={{ display: "flex" }}>
                    {isUser ? (
                      <>
                        <HeaderNav
                          user={userDetail}
                          menuCollapsed={menuCollapsed}
                          setMenuCollapsed={setMenuCollapsed}
                        />
                        <div
                          id="main-content-wrapper"
                          style={{
                            width: menuCollapsed
                              ? "calc(100% - 60px)"
                              : "calc(100% - 250px)",
                            marginLeft: "auto",
                            transition: "width 0.3s ease",
                          }}
                          className="mb-100"
                        >
                          <Routes>
                            <Route path="/" element={<EmpOverview />}></Route>
                            {/* <Route path="/emp-dashboard" element={<HeaderNav />}></Route> */}
                            <Route
                              path="/emp-overview"
                              element={<EmpOverview />}
                            ></Route>
                            <Route
                              path="/global-notification"
                              element={<GlobalNotification />}
                            ></Route>
                            <Route
                              path="/employees"
                              element={<Employees />}
                            ></Route>
                            <Route
                              path="/add-employee"
                              element={<AddEmployee />}
                            ></Route>
                            <Route
                              path="/edit-employee/:id"
                              element={<UpdateEmployee />}
                            ></Route>
                            <Route
                              path="/clients"
                              element={<AllClients />}
                            ></Route>
                            <Route
                              path="/add-client"
                              element={<AddClient />}
                            ></Route>
                            <Route
                              path="/edit-client/:id"
                              element={<EditClient />}
                            ></Route>
                            <Route
                              path="/teams"
                              element={<Departments />}
                            ></Route>
                            <Route
                              path="/all-services/:id"
                              element={<Services />}
                            ></Route>
                            <Route
                              path="/all-reports/:id"
                              element={<AllReports />}
                            ></Route>
                            <Route
                              path="/all-tasks"
                              element={<AllTasksTable />}
                            ></Route>
                            <Route
                              path="/all-tasks/:filter"
                              element={<AllTasksTable />}
                            ></Route>
                            <Route
                              path="/add-new-task"
                              element={<AddTask />}
                            ></Route>
                            <Route
                              path="/all-projects"
                              element={<AllProjects />}
                            ></Route>
                            <Route
                              path="/all-projects/:id"
                              element={<AllProjects />}
                            ></Route>
                            <Route
                              path="/manage-project/:id"
                              element={<ManageProject />}
                            ></Route>
                            <Route
                              path="/add-project"
                              element={<AddProject />}
                            ></Route>
                            <Route
                              path="/edit-project/:id"
                              element={<EditProject />}
                            ></Route>
                            <Route
                              path="/tickets"
                              element={<Tickets />}
                            ></Route>
                            <Route
                              path="/create-ticket"
                              element={<CreateTicket />}
                            ></Route>
                            <Route
                              path="/leaves-overview"
                              element={<LeavesOverview />}
                            ></Route>
                            <Route
                              path="/employee-leave/:id"
                              element={<LeavesOverview />}
                            ></Route>
                            <Route
                              path="/leave-application-form"
                              element={<LeaveWFHForm />}
                            ></Route>
                            <Route
                              path="/attendance"
                              element={<Attendance />}
                            ></Route>
                            <Route
                              path="/employee-attendance/:id"
                              element={<Attendance />}
                            ></Route>
                            <Route
                              path="/all-meetings"
                              element={<Meetings />}
                            ></Route>
                            <Route
                              path="/schedule-new-meeting"
                              element={<NewMeeting />}
                            ></Route>
                            <Route
                              path="/edit-meeting/:id"
                              element={<EditMeeting />}
                            ></Route>
                            <Route
                              path="/account-setting"
                              element={<AccountSetting />}
                            ></Route>
                            <Route
                              path="/social-media-report/:id"
                              element={<SocialMediaNos />}
                            ></Route>
                            <Route
                              path="/add-social-media-report"
                              element={<AddSMReport />}
                            ></Route>
                            <Route
                              path="/all-performance-reports"
                              element={<AllPerformanceReports />}
                            ></Route>
                            <Route
                              path="/performance-marketing-stats"
                              element={<PerformanceMarketingStats />}
                            ></Route>
                            <Route
                              path="/sm-calender"
                              element={<CalendarMain />}
                            ></Route>
                            <Route
                              path="/calender"
                              element={<UserCalendar />}
                            ></Route>
                            <Route
                              path="/emp-overview"
                              element={<EmpOverview />}
                            />
                            {/* <Route path="/all-notifications" element={<AllNotifications />} /> */}
                            <Route
                              path="/all-notifications"
                              element={<AllNotifications />}
                            />
                            <Route path="/user-role" element={<RolesTable />} />
                            {/* notes  */}
                            {/* <Route path="/all-notes" element={<AllNotes />} />
                            <Route path="/add-note" element={<NotesEditor />} /> */}
                            <Route
                              path="/edit-note/:id"
                              element={<NotesEditor />}
                            />
                            <Route
                              path="*"
                              element={<Navigate to="/emp-overview" />}
                            />
                          </Routes>
                        </div>
                      </>
                    ) : (
                      <Routes>
                        <Route path="/" element={<SignIn />}></Route>
                        <Route path="/sign-in" element={<SignIn />}></Route>
                        <Route path="/sign-up" element={<SignUp />}></Route>
                        <Route
                          path="*"
                          element={<Navigate to="/sign-in" replace />}
                        />
                      </Routes>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </SocketProvider>
    </>
  );
}

export default App;
