import { createSlice } from "@reduxjs/toolkit";

const leaveReducer = createSlice({
    name: 'leave',
    initialState: {
        leaves: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getLeavesStart: (state) => {
            state.isFetching = true;
        },
        getLeavesSuccess: (state, action) => {
            state.isFetching = false;
            state.leaves = action.payload;
        },
        getLeavesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add leave actions 
        addLeaveStart: (state) => {
            state.isFetching = true;
        },

        addLeaveSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.leaves = [...state.leaves, newUser];
        },

        addLeaveFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteLeaveStart: (state) => {
            state.isFetching = true;
        },

        deleteLeaveSuccess: (state, action) => {
            state.isFetching = false;
            state.leaves.splice(
                state.leaves.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteLeaveFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // // Update user actions
        updateLeaveStart: (state) => {
            state.isFetching = true;
        },

        updateLeaveSuccess: (state, action) => {
            state.isFetching = false;
            const leaveIndex = state.leaves.leavesData.findIndex(leave => leave._id === action.payload._id);
            if (leaveIndex !== -1) {
                state.leaves.leavesData[leaveIndex] = action.payload;
            }

            // Recalculate leave and WFH counts
            let totalLeavesTaken = 0;
            let totalWFHTaken = 0;
            let pendingRequestCount = 0;

            state.leaves.leavesData.forEach(leave => {
                leave.days.forEach(day => {
                    if (day.typeOfLeave === 'full_day') totalLeavesTaken += 1;
                    else if (day.typeOfLeave === 'work_from_home') totalWFHTaken += 1;
                    else if (day.typeOfLeave === 'half_day') totalLeavesTaken += 0.5;
                });

                // Count pending requests
                if (leave.isApproved === "pending") {
                    pendingRequestCount += 1;
                }
            });

            // Update the Redux state
            state.leaves.takenLeaves = totalLeavesTaken;
            state.leaves.takenWFH = totalWFHTaken;
            state.leaves.pendingRequest = pendingRequestCount;
        },

        updateLeaveFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getLeavesStart, getLeavesSuccess, getLeavesFailure, deleteLeaveStart, deleteLeaveSuccess, deleteLeaveFailure, addLeaveFailure, addLeaveStart, addLeaveSuccess, updateLeaveStart, updateLeaveSuccess, updateLeaveFailure } = leaveReducer.actions;
export default leaveReducer.reducer;

