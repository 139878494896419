import React, { useEffect } from "react";
import Breadcrumb from "../../common-components/breadcrumb/Breadcrumb";
import DynamicForm from "../../common-components/form/DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import useOrganisationId from "../../custom-hooks/useOrganisationId";
import { getAllClients, getAllEmployees } from "../../redux/apiCalls";
import MiniLoader from "../../common-components/miniLoader/MiniLoader";

const NewMeeting = () => {
  const dispatch = useDispatch();
  const organisationId = useOrganisationId();

  useEffect(() => {
    getAllClients(dispatch, organisationId);
  }, [dispatch]);

  useEffect(() => {
    getAllEmployees(dispatch, organisationId);
  }, [dispatch]);

  const { employees: allEmployees, isFetching } = useSelector(
    (state) => state.employee
  );

  const meetingSchema = [
    {
      name: "agenda",
      type: "text",
      label: "Meeting Agenda",
      placeholder: "Enter meeting agenda",
      validation: "required",
    },
    {
      name: "link",
      type: "text",
      label: "Meeting Link",
      placeholder: "Enter meeting link",
      validation: "required",
    },
    // {
    //     name: "clientId",
    //     type: "dropdownWithArrayObjectOption",
    //     label: "Client Name",
    //     options: allClients,
    //     valueToBeRender: "name",
    //     // multiple : true,
    //     // placeholder: "Enter brand name",
    //     validation: "required",
    // },
    {
      name: "meetingDate",
      type: "date",
      label: "Date",
      validation: "date",
    },
    {
      name: "startTime",
      type: "time",
      label: "meeting start time",
      validation: "time",
    },
    {
      name: "endTime",
      type: "time",
      label: "meeting end time",
      validation: "time",
    },
    // {
    //     name: "projectLeads",
    //     type: "dropdownWithArrayObjectOption",
    //     label: "Project Leads",
    //     options: allEmployees,
    //     valueToBeRender: "name",
    //     multiple: true,
    // },
    // {
    //   name: "projectPointOfContact",
    //   type: "text",
    //   label: "Point of contact",
    //   placeholder: "Enter person name",
    //   validation: "required",
    // },
    {
      name: "teams",
      type: "dropdownWithArrayObjectOption",
      label: "Select participents",
      options: allEmployees,
      valueToBeRender: "name",
      multiple: true,
    },
    // {
    //     name: "priority",
    //     type: "dropdown",
    //     label: "Select project priority",
    //     options: ["low", "medium", "high"],
    // },
    // {
    //     name: "status",
    //     type: "dropdown",
    //     label: "Select project status",
    //     options: ["inactive", "in progress", "delayed", "paused", "completed", "suspended"],
    // },
  ];

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <>
      <Breadcrumb mainTitle="Schedule a Meeting" />
      <div className="add-emp-form-wrapper custom-padding">
        <DynamicForm
          schema={meetingSchema}
          httpMethod="post"
          apiEndPoint="meeting/create"
        />
      </div>
    </>
  );
};

export default NewMeeting;
