import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"; // Assuming you're using Redux for state management
// import { logoutUser } from '../redux/userRedux';
import "./TokenExpirationInterceptor.css";
import { Link } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { logoutUser } from "../../redux/userRedux";
import CommonButton from "../button/CommonButton";

const TokenExpirationInterceptor = () => {
  const dispatch = useDispatch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    // const interceptor = axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response && error.response.status === 403) {
    //       // Logout if the API response indicates that the token is not valid
    //       dispatch(logoutUser());
    //     }
    //     return Promise.reject(error);
    //   }
    // );

    // return () => {
    //   // Clean up the interceptor when component unmounts
    //   axios.interceptors.response.eject(interceptor);
    // };

    // Add a response interceptor
    userRequest.interceptors.response.use(
      (response) => {
        //setLocalStorageToken(token);
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
          setIsPopupVisible(true);
          //(`unauthorized :)`);
          //localStorage.removeItem("persist:root");
          //removeLocalStorageToken
          //window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  if (isPopupVisible) {
    return (
      <>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="token-expire-container">
                <div className="expired-popup">
                  <h1>Your session has expired.</h1>
                  <Link to={"/sign-in"}>
                    <CommonButton
                      btnName={"Sign In"}
                      btnClass="common-btn btn-edit"
                      onClickAction={() => setIsPopupVisible(false)}
                    />
                    {/* <button className="uniBtnBlue" onClick={() => setIsPopupVisible(false)}>Login</button> */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default TokenExpirationInterceptor;
