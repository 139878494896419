import { Col, Container, Row } from "react-bootstrap";
import "./Employees.css";
import EmployeeCard from "../../../common-components/cards/employee-card/EmployeeCard";
import CommonButton from "../../../common-components/button/CommonButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllEmployees, getAllUsers } from "../../../redux/apiCalls";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import DropDownButton from "../../../common-components/dropdown-button/DropDownButton";
import {
  getUniqueDepartments,
  getUniqueEmployeeStatuses,
  hasPermission,
} from "../../../config";
import NoResults from "../../../common-components/no-results/NoResults";
import { GoPersonAdd } from "react-icons/go";

const Employees = () => {
  const state = useSelector((state) => state);
  const { isFetching, employees: allEmployees } = useSelector(
    (state) => state?.employee
  );

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const organisationId = currentUser?.isUserOrganisationOwner
      ? currentUser._id
      : currentUser?.organisationId;
    getAllEmployees(dispatch, organisationId);
  }, [dispatch]);

  useEffect(() => {
    setFilteredEmployees(sortEmployeesByStatus(allEmployees));
  }, [allEmployees]);

  // Add this new function to sort employees
  const sortEmployeesByStatus = (employees) => {
    if (!employees) return [];
    return [...employees].sort((a, b) => {
      // Put former employees last
      if (a.employeeStatus === "former" && b.employeeStatus !== "former") {
        return 1;
      }
      if (a.employeeStatus !== "former" && b.employeeStatus === "former") {
        return -1;
      }
      return 0;
    });
  };

  // State for filtered employees
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const uniqueDepartments = getUniqueDepartments(allEmployees);
  const uniqueEmployeeStatuses = getUniqueEmployeeStatuses(allEmployees);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDateOrder, setSelectedDateOrder] = useState(null);

  // State for active filters
  const [activeFilters, setActiveFilters] = useState({
    department: null,
    status: null,
    dateOrder: null,
  });

  // Modify the applyFilters function
  const applyFilters = () => {
    let filtered = [...allEmployees];

    // Filter by department
    if (activeFilters?.department) {
      filtered = filtered.filter(
        (employee) => employee.department === activeFilters.department
      );
    }

    // Filter by status
    if (activeFilters?.status) {
      filtered = filtered.filter(
        (employee) => employee.employeeStatus === activeFilters.status
      );
    }

    // Sort by date
    if (activeFilters?.dateOrder) {
      filtered.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return activeFilters.dateOrder === "New to Old"
          ? dateB - dateA
          : dateA - dateB;
      });
    } else {
      // If no date sorting is applied, sort by employee status (former employees last)
      filtered = sortEmployeesByStatus(filtered);
    }

    setFilteredEmployees(filtered);
  };

  // Update department filter
  const handleDepartmentFilter = (department) => {
    setSelectedDepartment(department);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      department,
    }));
  };

  // Update status filter
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      status,
    }));
  };

  // Update date sorting filter
  const handleDateSort = (order) => {
    setSelectedDateOrder(order);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      dateOrder: order,
    }));
  };

  // Reset all filters
  const resetFilters = () => {
    setActiveFilters({
      department: null,
      status: null,
      dateOrder: null,
    });
    setSelectedDepartment(null);
    setSelectedStatus(null);
    setSelectedDateOrder(null);
    setFilteredEmployees(allEmployees);
  };

  // Reapply filters whenever activeFilters changes
  useEffect(() => {
    applyFilters();
  }, [activeFilters]);

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <div className="employees-wrapper">
      {/* breadcrumb */}
      <Breadcrumb mainTitle="Employees" />

      <div className="add-emp-filter-btns-conatiner custom-padding pb-0">
        <Container>
          <Row className="flex-column align-content-end align-items-end">
            {/* filter button column  */}
            <Col lg={8}>
              <Row className="align-items-center">
                <Col lg={4}>
                  <p className="m-0 sort-by"> Sort By:&nbsp;</p>
                </Col>

                <Col lg={8}>
                  <div className="filters-btns-container">
                    {/* Department Dropdown */}
                    <DropDownButton
                      label="Department"
                      options={uniqueDepartments}
                      onSelect={(selected) => handleDepartmentFilter(selected)}
                      selected={selectedDepartment} // Pass selected value
                    />

                    {/* Employee Status Dropdown */}
                    <DropDownButton
                      label="Status"
                      options={uniqueEmployeeStatuses}
                      onSelect={handleStatusFilter}
                      selected={selectedStatus} // Pass selected value
                    />

                    {/* Date Sorting Dropdown */}
                    <DropDownButton
                      label="Joining"
                      options={[
                        { label: "New to Old", value: "New to Old" },
                        { label: "Old to New", value: "Old to New" },
                      ]}
                      onSelect={handleDateSort}
                      selected={selectedDateOrder} // Pass selected value
                    />

                    {/* Reset Filters Button */}
                    <CommonButton
                      onClickAction={resetFilters}
                      btnClass="btn-edit new-small-btn"
                      btnName="Reset Filters"
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            {/* add employee column  */}
            <Col lg={4}>
              <div className="add-emp-btn text-end mt-3 d-flex justify-content-end">
                {hasPermission(currentUser, ["add_employee"]) && (
                  <Link to={"/add-employee"}>
                    <CommonButton
                      btnName={
                        <>
                          Add Employee &nbsp;
                          <GoPersonAdd />
                        </>
                      }
                      btnClass="btn-edit common-btn new-icon-btn"
                    />
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* employee cards */}
      {filteredEmployees?.length > 0 ? (
        // return(
        <EmployeeCard details={filteredEmployees} />
      ) : (
        // )
        // <p>No results found</p>
        <NoResults />
      )}
    </div>
  );
};

export default Employees;
