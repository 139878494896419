import { userRequest } from "../requestMethod"
import { getAttendancesFailure, getAttendancesStart, getAttendancesSuccess } from "./attendanceRedux";
import { getClientsFailure, getClientsStart, getClientsSuccess } from "./clientRedux";
import { getEmployeesFailure, getEmployeesStart, getEmployeesSuccess } from "./employeeRedux";
import { getGlobalNotificationFailure, getGlobalNotificationStart, getGlobalNotificationSuccess } from "./globalNotificationRedux";
import { getLeavesFailure, getLeavesStart, getLeavesSuccess } from "./leaveRedux";
import { getMeetingsFailure, getMeetingsStart, getMeetingsSuccess } from "./meetingRedux";
import { getProjectsFailure, getProjectsStart, getProjectsSuccess } from "./projectRedux";
import { deleteUserStart, deleteUserSuccess, getUsersFailure, getUsersStart, getUsersSuccess } from "./registeredUserRedux";
import { getRolesFailure, getRolesStart, getRolesSuccess } from "./roleRedux";
import { getSMReportsFailure, getSMReportsStart, getSMReportsSuccess } from "./smReportRedux";


// Get all registered 
export const getAllUsers = async (dispatch) => {
    dispatch(getUsersStart());
    try {
        const res = await userRequest.get('/user/all');
        dispatch(getUsersSuccess(res.data));
    } catch (error) {
        dispatch(getUsersFailure());
    }
}


// Get all employees 
export const getAllEmployees = async (dispatch) => {
    dispatch(getEmployeesStart());
    try {
        const res = await userRequest.get(`/employee/all`);
        dispatch(getEmployeesSuccess(res.data));
    } catch (error) {
        dispatch(getEmployeesFailure());
    }
}

// Get all clients 
export const getAllClients = async (dispatch) => {
    dispatch(getClientsStart());
    try {
        const res = await userRequest.get(`/client/all`);
        dispatch(getClientsSuccess(res.data));
    } catch (error) {
        dispatch(getClientsFailure());
    }
}

// Get all projects 
export const getAllProjects = async (dispatch) => {
    dispatch(getProjectsStart());
    try {
        const res = await userRequest.get(`/project/all`);
        dispatch(getProjectsSuccess(res.data));
    } catch (error) {
        dispatch(getProjectsFailure());
    }
}

// Get all leaves 
export const getAllLeaves = async (dispatch) => {
    dispatch(getLeavesStart());
    try {
        const res = await userRequest.get(`/leave/all`);
        dispatch(getLeavesSuccess(res.data));
    } catch (error) {
        dispatch(getLeavesFailure());
    }
}

// Get all leaves of specific employee
export const getAllLeavesOfEmployee = async (dispatch, employeeId) => {
    dispatch(getLeavesStart());
    try {
        const res = await userRequest.get(`/leave/employee/${employeeId}`);
        dispatch(getLeavesSuccess(res.data));
    } catch (error) {
        dispatch(getLeavesFailure());
    }
}

// Get all leaves 
export const getAllSMReports = async (dispatch, clientId) => {
    dispatch(getSMReportsStart());
    try {
        const res = await userRequest.get(`/sm-report/all/${clientId}`);
        dispatch(getSMReportsSuccess(res.data));
    } catch (error) {
        dispatch(getSMReportsFailure());
    }
}

// Get all leaves 
export const getGlobalNotification = async (dispatch) => {
    dispatch(getGlobalNotificationStart());
    try {
        const res = await userRequest.get(`/global-notification/get`);
        dispatch(getGlobalNotificationSuccess(res.data ? res.data : [] ));
    } catch (error) {
        dispatch(getGlobalNotificationFailure());
    }
}


// Get all meetings 
export const getAllMeetings = async (dispatch) => {
    dispatch(getMeetingsStart());
    try {
        const res = await userRequest.get(`/meeting/all`);
        dispatch(getMeetingsSuccess(res.data));
    } catch (error) {
        dispatch(getMeetingsFailure());
    }
}

// Get all attendance 
export const getAllAttendance = async (dispatch) => {
    dispatch(getAttendancesStart());
    try {
        const res = await userRequest.get(`/attendance/all`);
        dispatch(getAttendancesSuccess(res.data));
    } catch (error) {
        dispatch(getAttendancesFailure());
    }
}

// Get all attendance of specific employee
export const getAllAttendanceOfEmployee = async (dispatch, employeeId) => {
    dispatch(getAttendancesStart());
    try {
        const res = await userRequest.get(`/attendance/employee/${employeeId}`);
        dispatch(getAttendancesSuccess(res.data));
    } catch (error) {
        dispatch(getAttendancesFailure());
    }
}

// Get all attendance 
export const getAllRoles = async (dispatch) => {
    dispatch(getRolesStart());
    try {
        const res = await userRequest.get(`/role/all`);
        dispatch(getRolesSuccess(res.data));
    } catch (error) {
        dispatch(getRolesFailure());
    }
}


// // Delete employee
// export const deleteEmployee = async (dispatch, id) => {
//     dispatch(deleteUserStart());
//     try {
//         const res = await userRequest.delete(`/user/${id}`);
//         if(res?.status === 200){
//             dispatch(deleteUserSuccess());
//         }
//     } catch (error) {
        
//     }

// }


