import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../sign-up/SignUp.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import CommonButton from "../../common-components/button/CommonButton";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const SignUp = () => {
  //  states for inputs
  const [userName, setUserName] = useState("");
  const [fullName, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);

  // states for error onclick
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState({
    userName: "",
    fullName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  // Success messgae
  const [success, setSuccess] = useState(false);

  // functions to handle editableInputTypes, errors and submissions
  const handleUserName = (e) => {
    setUserName(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, userName: "" }));
    setSubmit(false);
    setSuccess(false);
  };

  const handleFullName = (e) => {
    setFullname(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, fullName: " " }));
    setSubmit(false);
    setSuccess(false);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, email: "" }));
    setSubmit(false);
    setSuccess(false);
  };

  const handlePhoneNo = (e) => {
    setPhoneNo(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, phone: "" }));
    setSubmit(false);
    setSuccess(false);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, password: " " }));
    setSubmit(false);
    setSuccess(false);
  };

  const handleConfirmPwd = (e) => {
    setConfirmPassword(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    setSubmit(false);
    setSuccess(false);
  };

  const validateForm = () => {
    let formIsValid = true;

    // userName validation
    if (userName === "") {
      setError((prevErrors) => ({
        ...prevErrors,
        userName: "Username is required",
      }));
      formIsValid = false;
    }

    // full name validation
    if (fullName === "") {
      setError((prevErrors) => ({
        ...prevErrors,
        fullName: "Please enter your Full Name",
      }));
      formIsValid = false;
    } else if (fullName !== "") {
      setError((prevErrors) => ({
        ...prevErrors,
        fullName: "Please enter humanly name!",
      }));
      formIsValid = false;
    }

    // email validation
    if (email === "") {
      setError((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
      formIsValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError((prevErrors) => ({
        ...prevErrors,
        email: "Enter a valid Email address.",
      }));
      formIsValid = false;
    }

    // Phone number validation
    if (phone === "") {
      setError((prevErrors) => ({
        ...prevErrors,
        phone: "Phone  Number is Required",
      }));
      formIsValid = false;
    } else if (isNaN(phone)) {
      setError((prevErrors) => ({
        ...prevErrors,
        phone: "Phone number must be a number.",
      }));
      formIsValid = false;
    }

    // password validation
    if (password === "") {
      setError((prevErrors) => ({
        ...prevErrors,
        password: "Password is required.",
      }));
      formIsValid = false;
    } else if (password.length < 8) {
      setError((prevErrors) => ({
        ...prevErrors,
        password: "Password should have at least 8 characters.",
      }));
      formIsValid = false;
    } else if (!/[a-zA-Z]/.test(password)) {
      setError((prevErrors) => ({
        ...prevErrors,
        password: "Password should contain atleast one letter.",
      }));
      formIsValid = false;
    } else if (!/[0-9]/.test(password)) {
      setError((prevErrors) => ({
        ...prevErrors,
        password: "Password should contain atleast one digit.",
      }));
      formIsValid = false;
    }

    // Confirm Password Validation
    if (confirmPassword === "") {
      setError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Write password again!",
      }));
    } else if (confirmPassword !== password) {
      setError((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmit(true);
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div className="signup-wrapper container">
      {/* page content  */}
      <section className="content-register-form">
        <Container>
          <Row className="align-items-center gx-5">
            {/* image col  */}
            <Col lg={5}>
              <div className="signup-content-wrapper">
                <div className="reg-form-head text-center pb-lg-4">
                  <h2>Register here!</h2>
                </div>

                <div className="signup-pg-img">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/sign-up/sign-up-img.png`}
                    alt=""
                  />
                </div>
              </div>
            </Col>

            {/* form col  */}
            <Col lg={7}>
              <div className="registration-form-wrapper">
                <div className="org-logo text-center mb-4">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo/uniworld-logo.png`}
                    alt=""
                  />
                </div>
                <div className="signin-head text-center">
                  <p>Hi, Welcome to your Virtual Workspace</p>
                </div>
                <div className="sucess-msg-wrapper">
                  {success && (
                    <div className="success-msg">
                      <p>
                        Hi {fullName}, Welcome! Your registration was
                        successful.
                      </p>
                    </div>
                  )}
                </div>
                {step === 1 && (
                  <form className="signup-form-wrapper">
                    {/* Organization Name */}
                    <Form.Group className="mb-3">
                      <Form.Label>Organization Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="organizationName"
                        required
                        placeholder="Organization Name"
                      />
                      {error.organizationName && (
                        <div className="error-msg">
                          {error.organizationName}
                        </div>
                      )}
                    </Form.Group>

                    <div className="sf-email-phone d-flex justify-content-between">
                      {/* Email */}
                      <Form.Group className="sf-email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                        {/* {error.email && (
                          <div className="error-msg">{error.email}</div>
                        )} */}
                      </Form.Group>

                      {/* Mobile Number with Country Code */}
                      <Form.Group className="sf-phone">
                        <Form.Label>Mobile Number</Form.Label>
                        <PhoneInput
                          defaultCountry="IN"
                          value={phone}
                          onChange={setPhoneNo}
                          placeholder="000-000-0000"
                        />
                        {error.mobile && (
                          <div className="error-msg">{error.mobile}</div>
                        )}
                      </Form.Group>
                    </div>

                    {/* Address */}
                    <Form.Group className="mb-3">
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine1"
                        placeholder="Address Line 1"
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Address Line 2</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLine1"
                        placeholder="Address Line 2"
                        required
                      />
                    </Form.Group>

                    <div className="pCode-city-state-wrapper d-flex column-gap-2">
                      {/* Postal Code */}
                      <Form.Group className="w-100">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="postalCode"
                          placeholder="Postal Code"
                          required
                        />
                      </Form.Group>

                      {/* City/District */}
                      <Form.Group className="w-100">
                        <Form.Label>City/District</Form.Label>
                        <Form.Control
                          type="text"
                          name="city/district"
                          placeholder="City/District"
                          required
                        />
                      </Form.Group>

                      {/* state */}
                      <Form.Group className="w-100">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          name="state"
                          placeholder="State"
                          required
                        />
                      </Form.Group>
                    </div>

                    {/* Submit Button */}
                    <div className="sf-submit-btn-wrapper text-center">
                      <CommonButton
                        onClickAction={nextStep}
                        btnClass="primary-btn reg-form-btn common-btn"
                        btnName="Next"
                      />
                    </div>
                  </form>
                )}

                {step === 2 && (
                  <form className="signup-form-wrapper">
                    {/* Type of Organization */}
                    <Form.Group className="mb-3">
                      <Form.Label>Type of Organization</Form.Label>
                      <Form.Control
                        type="text"
                        name="organizationType"
                        required
                      />
                    </Form.Group>

                    {/* Business Description */}
                    <Form.Group className="mb-3">
                      <Form.Label>Business Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="businessDescription"
                        required
                      />
                    </Form.Group>

                    {/* Working Days */}
                    <Form.Group className="mb-3">
                      <Form.Label>Working Days</Form.Label>
                      <div className="working-days-wrapper d-flex gap-2 align-items-center">
                        <Form.Label>From:</Form.Label>
                        <Form.Select name="workingDaysFrom">
                          <option>Monday</option>
                          <option>Tuesday</option>
                          <option>Wednesday</option>
                          <option>Thursday</option>
                          <option>Friday</option>
                          <option>Saturday</option>
                          <option>Sunday</option>
                        </Form.Select>

                        <Form.Label>To:</Form.Label>
                        <Form.Select name="workingDaysTo">
                          <option>Monday</option>
                          <option>Tuesday</option>
                          <option>Wednesday</option>
                          <option>Thursday</option>
                          <option>Friday</option>
                          <option>Saturday</option>
                          <option>Sunday</option>
                        </Form.Select>
                      </div>
                    </Form.Group>

                    <div className="leaves-wfh-container d-flex column-gap-3">
                      {/* Number of Leaves */}
                      <Form.Group className="mb-3 sf-leaves">
                        <Form.Label>Number of Leaves</Form.Label>
                        <Form.Control
                          type="number"
                          name="totalLeaves"
                          placeholder="Total Leaves"
                        />
                      </Form.Group>

                      {/* Number of WFH (If Any) */}
                      <Form.Group className="mb-3 sf-wfh">
                        <Form.Label>Number of WFH (If Any)</Form.Label>
                        <Form.Control
                          type="number"
                          name="totalWFH"
                          placeholder="Total WFHs"
                        />
                      </Form.Group>
                    </div>

                    {/* Number of Holidays */}
                    <div className="holiday-count-choose-holidays d-flex column-gap-3">
                      <Form.Group className="mb-3 sf-holiday-count">
                        <Form.Label>Number of Holidays</Form.Label>
                        <Form.Control
                          type="number"
                          name="totalHolidays"
                          placeholder="Total Holidays"
                        />
                      </Form.Group>

                      {/* Choose Holidays */}
                      <Form.Group className="mb-3 sf-choose-holidays">
                        <Form.Label>Choose Holidays</Form.Label>
                        <Form.Control type="date" name="chooseHolidays" />
                      </Form.Group>
                    </div>

                    <div className="d-flex justify-content-center column-gap-3">
                      <CommonButton
                        onClickAction={prevStep}
                        btnClass="primary-btn reg-form-btn common-btn"
                        btnName="Previous"
                      />
                      <CommonButton
                        btnClass="primary-btn reg-form-btn common-btn"
                        btnName="Submit"
                      />
                    </div>
                  </form>
                )}

                {/* Existing Customer Sign-In */}
                <div className="existing-customer-signin">
                  <p>
                    Already have an account? <Link to="/sign-in">Sign In</Link>.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SignUp;
