import React, { useContext, useEffect, useState } from "react";
import { FaRegBell } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./NotificationPopup.css";
import NotificationsData from "../notifications-data/NotificationsData";
import { notiContent } from "../../../config";
import { MdOutlineCancel } from "react-icons/md";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import SocketContext from "../../socket-provider/socketContext";
import { userRequest } from "../../../requestMethod";
import Notification from "../notification/Notification";

const NotificationPopup = () => {
  const { notifications, setNotifications, unreadCount, markNotificationsAsRead } = useContext(SocketContext);
  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);
  const handleBellClick = async () => {
    setIsNotificationPopupOpen(!isNotificationPopupOpen);
    if (!isNotificationPopupOpen) {
      try {
        const res = await userRequest.put(`/notification/mark-all-seen`);
        if (res.status === 200) {
          setNotifications((prev) =>
            prev?.map((noti) => ({ ...noti, isSeen: true }))
          );
        }
      } catch (error) {
        console.error("Error marking notifications as seen:", error);
      }
    }
  };

  const handleOpenPopup = () => {
    setIsNotificationPopupOpen(false);
  };

  const handleMarkAllAsRead = async () => {
    try {
      // await axios.post("/api/notifications/markSingleAsRead", { notificationId: id });
      const res = await userRequest.put('/notification/mark-all-read');
      if (res?.status === 200) {
        setNotifications([])
        // Update the state correctly (instead of setting an empty array)
        // setNotifications((prev) => prev.map((noti) => ({ ...noti, isRead: true })));
      }
      // Update the UI by calling the parent function
      // onMarkAsRead(id);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkSingleAsRead = async (notificationId) => {
    try {
      // await axios.post("/api/notifications/markSingleAsRead", { notificationId: id });
      const res = await userRequest.put(`/notification/mark-single-as-read/${notificationId}`);
      if (res?.status === 200) {
        // Update the specific notification in the state
        setNotifications((prev) => prev.filter((noti) =>
          noti._id !== notificationId
        ));
      }
      // console.log("object")

      // Update the UI by calling the parent function
      // onMarkAsRead(id);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };


  return (
    <>
      {/* notification  */}
      <div className="notification-wrapper position-relative ">
        <button className="clickable notif-icon-wrapper" onClick={handleBellClick}>
          <FaRegBell className="notif-icon" />
          {/* {notifications?.length > 0 && (
            <p className="notifications-count">{notifications?.length > 99 ? "99+" : notifications?.length}</p>
          )} */}
          {notifications?.filter(noti => !noti.isSeen)?.length > 0 && (
            <p className="notifications-count">
              {notifications?.filter(noti => !noti.isSeen)?.length > 99 ? "99+" : notifications?.filter(noti => !noti.isSeen)?.length}
            </p>
          )}

        </button>

      </div>

      {isNotificationPopupOpen && (
        <div className="notification-popup">
          <div className="notification-popup-header d-flex align-items-center justify-content-between column-gap-2">
            <h5 className="m-0">
              <FaRegBell /> Notifications
            </h5>

            {/* close btn  */}
            <div className="close-notification-btn">
              <MdOutlineCancel onClick={handleOpenPopup} className="clickable" />
            </div>
          </div>

          {notifications?.length > 0 ? (
            <>
              <div className="noti-btns d-flex align-items-center justify-content-between">
                {/* <Link to="/all-notifications" className="noti-btn pSize">
                  View All
                </Link> */}
                {/* <button className="m-0 pSize" onClick={handleMarkAllAsRead}>Mark all as read</button> */}
              </div>

              <div className="notification-btn-container">
                {/* <NotificationsData limit={20} notifications={notifications}  /> */}

                <div className="custom-padding">
                  {notifications?.length > 0 ? (
                    <div className="notification-container">
                      {notifications?.map((noti, index) => (
                        <Notification
                          handleMarkSingleAsRead={handleMarkSingleAsRead}
                          notification={noti}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="no-notification text-center">
                      <p>No Notifications</p>
                    </div>
                  )}
                </div>

                {/* show more btn  */}
                {/* <div className="show-more-btn-wrapper text-center">
                  <Link to="/all-notifications" className="show-more-btn">
                    Show More
                  </Link>
                </div> */}
              </div>
            </>
          ) : (
            <div className="no-notification text-center">
              <p>No Notifications</p>
            </div>
          )}

        </div>
      )}
    </>
  );
};

export default NotificationPopup;
