import { Card, Col, Container, Row } from "react-bootstrap";
import "./LeavesOverview.css";
import CommonButton from "../../../../common-components/button/CommonButton";
import { Link, useParams } from "react-router-dom";
import GeneralTable from "../../../../common-components/gen-table/GeneralTable";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { getAllLeaves, getAllLeavesOfEmployee } from "../../../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { SlCalender } from "react-icons/sl";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { LuTent } from "react-icons/lu";
import { MdOutlinePendingActions } from "react-icons/md";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import { changeDateFormat } from "../../../../config";
import Popup from "../../../../common-components/popup/Popup";
import { userRequest } from "../../../../requestMethod";
import { updateLeaveFailure, updateLeaveStart, updateLeaveSuccess } from "../../../../redux/leaveRedux";

const LeavesOverview = () => {
  const dispatch = useDispatch();
  const { id: employeeId } = useParams();
  const [isManagerView, setIsManagerView] = useState(false)

  useEffect(() => {
    if (employeeId) {
      setIsManagerView(true);
      getAllLeavesOfEmployee(dispatch, employeeId);
    } else {
      setIsManagerView(false);
      getAllLeaves(dispatch);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   getAllLeavesOfEmployee(dispatch, employeeId);
  // }, [employeeId])

  const state = useSelector((state) => state);

  const { leaves: allLeaves, isFetching } = useSelector((state) => state.leave);
  const [formattedLeavesData, setFormattedLeavesData] = useState([]);
  const [currentTableData, setCurrentTableData] = useState(allLeaves?.leavesData || [])
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Data has been added");
  // const [selectedRole, setSelectedRole] = useState(null); // State for the selected role to edit
  const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
  const [popupButtonName, setPopupButtonName] = useState("");
  const [leaveIdToUpdate, setLeaveIdToUpdate] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState("");

  // const leaveTableHeader = ["S. No.", "Date", "Type of Leave", "Reason"];

  const leaveHeaders = [
    { headerName: "Applied Date", valueToBeRender: "appliedDate" },
    { headerName: "Days", valueToBeRender: "days" },
    { headerName: "Reason", valueToBeRender: "reason" },
    { headerName: "Status", valueToBeRender: "status", className: "text-capitalize" },
  ];


  const leaveTableHeader = [
    {
      headerName: "Applied Date",
      valueToBeRender: "createdAt",
      renderFunction: (params) => {
        return changeDateFormat(params)
      },
    },
    {
      headerName: "Leave Days",
      subHeaders: [
        { headerName: "Date", valueToBeRender: "add_employee" },
        { headerName: "leave type", valueToBeRender: "edit_employee" },
      ],
    },
    // {
    //   headerName: "Leave Days",
    //   // valueToBeRender: "typeOfLeave",
    // },
    {
      headerName: "Reason",
      valueToBeRender: "reason",
    },
    {
      headerName: "Status",
      valueToBeRender: "isApproved",
      className: "text-capitalized"
    },
  ];
  // const leaveTableHeader = [
  //   {
  //     headerName: "Date",
  //     valueToBeRender: "date",
  //     // renderFunction : (params) => {
  //     //   return changeDateFormat(params.value)
  //     // },
  //   },
  //   {
  //     headerName: "Type of Leave",
  //     valueToBeRender: "typeOfLeave",
  //   },
  //   {
  //     headerName: "Reason",
  //     valueToBeRender: "reason",
  //   },
  //   {
  //     headerName: "Status",
  //     valueToBeRender: "status",
  //   },
  // ];

  const [remainingLeaves, setRemainingLeaves] = useState(0);
  const [remainingWFH, setRemainingWFH] = useState(0);
  const [pendingRequest, setPendingRequest] = useState(0);

  useEffect(() => {
    setRemainingWFH(allLeaves?.remainingWFH);
    setRemainingLeaves(allLeaves?.remainingLeaves);
    setPendingRequest(allLeaves?.pendingRequest);
  }, [allLeaves]);


  // const transformLeaveData = (leaves) => {
  //   return leaves?.map((leave) => {
  //     // Map each day to an object where the day is the key
  //     const daysMap = leave?.days?.reduce((acc, day) => {
  //       acc[day.nameOfTheDay] = day.typeOfLeave;
  //       return acc;
  //     }, {});


  //     return {
  //       employeeId: leave.employeeId,
  //       organisationId: leave.organisationId,
  //       startDate: new Date(leave.startDate).toLocaleDateString(),
  //       endDate: new Date(leave.endDate).toLocaleDateString(),
  //       reason: leave.reason,
  //       isApproved: leave.isApproved,
  //       ...daysMap, // Add nested day data dynamically
  //     };
  //   });
  // };


  const transformLeaveData = (leaves) => {
    return leaves?.map((leave) => ({
      _id: leave._id,
      appliedDate: new Date(leave.createdAt).toLocaleDateString(),
      days: leave.days.map((day) => ({
        date: day.date,
        nameOfTheDay: day.nameOfTheDay,
        typeOfLeave: day.typeOfLeave,
      })),
      reason: leave.reason,
      status: leave.isApproved,
    }));
  };


  useEffect(() => {
    if (allLeaves) {
      setCurrentTableData(transformLeaveData(allLeaves?.leavesData));
    }
  }, [allLeaves]);

  const handleApprove = () => {
  }

  const handleReject = () => {
  }

  const handleAction = async () => {
    setIsPopupActive(false);
    setIsLoaderActive(true)
    dispatch(updateLeaveStart());
    try {
      const res = await userRequest.put(`leave/approve-reject/${leaveIdToUpdate}`, { leaveStatus });
      if (res.status == 200) {
        dispatch(updateLeaveSuccess(res.data.leaveRequest));
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setIsItConfimationPopup(false)
        setPopupText(res?.data?.message);
      }
    } catch (error) {
      dispatch(updateLeaveFailure());
      setIsLoaderActive(false);
      setIsItConfimationPopup(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setIsItConfimationPopup(false)
      setPopupText(error?.response?.data?.message);
    }
  }

  // const formatLeaveData = (leaves) => {
  //   let formattedData = [];
  //   leaves?.forEach((leave) => {
  //     leave?.days?.forEach((day, dayIndex) => {
  //       const date = new Date(leave.startDate);
  //       date.setDate(date.getDate() + dayIndex);
  //       formattedData.push({
  //         serialNumber: formattedData.length + 1,
  //         date: `${date.toLocaleDateString()}`,
  //         typeOfLeave: day.typeOfLeave,
  //         reason: leave.reason,
  //         status: leave.isApproved
  //       });
  //     });
  //   });
  //   return formattedData;
  // };

  // // const formattedLeavesData = formatLeaveData(allLeaves?.leavesData);

  // useEffect(() => {
  //   setFormattedLeavesData(formatLeaveData(allLeaves?.leavesData?.length > 0 ? allLeaves?.leavesData : []))
  // }, [allLeaves])

  const handleConfirmPopup = (popupStatus, leaveId, approvalType) => {
    setPopupButtonName(approvalType)
    setIsPopupActive(popupStatus);
    setIsItConfimationPopup(true);
    setLeaveIdToUpdate(leaveId);
    setLeaveStatus(approvalType)
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  if (isFetching) {
    return <MiniLoader />
  }


  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Leaves" />

      {/* Leaves/WFH section  */}
      <section className="leaves-cards-container custom-padding">
        <Container>
          <Row className="gx-5 justify-content-center">
            {/* total leaves card  */}
            <Col lg={3} md={6} sm={6}>
              <Card className="leave-wfh-card">
                <Card.Body className="d-flex flex-wrap flex-column align-items-center">
                  <div className="leave-wfh-card-icon">
                    <SlCalender />
                  </div>
                  <div className="leave-wfh-count">
                    <p>15</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Total Leaves/WFH</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Available leaves  */}
            <Col lg={3} md={6} sm={6}>
              <Card className="leave-wfh-card lwc-green">
                <Card.Body className="d-flex flex-wrap flex-column align-items-center">
                  <div className="leave-wfh-card-icon">
                    <LuTent />
                  </div>
                  <div className="leave-wfh-count">
                    <p>{remainingLeaves ? remainingLeaves : 0}</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Available Leaves</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Available WFH  */}
            <Col lg={3} md={6} sm={6}>
              <Card className="leave-wfh-card lwc-green">
                <Card.Body className="d-flex flex-wrap flex-column align-items-center">
                  <div className="leave-wfh-card-icon">
                    <MdOutlineAddHomeWork />
                  </div>
                  <div className="leave-wfh-count">
                    <p>{remainingWFH ? remainingWFH : 0}</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Available WFH</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* pending requests  */}
            <Col lg={3} md={6} sm={6}>
              <Card className="leave-wfh-card plr-orange">
                <Card.Body className="d-flex flex-wrap flex-column align-items-center">
                  <div className="leave-wfh-card-icon">
                    <MdOutlinePendingActions />
                  </div>
                  <div className="leave-wfh-count">
                    <p>{pendingRequest ? pendingRequest : 0}</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Pending Requests</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {
        !employeeId && (
          <div className="leave-apply-btn-container text-center">
            <Link to="/leave-application-form">
              <CommonButton
                btnName="Apply Now"
                btnClass="leave-apply-btn common-btn"
              />
            </Link>
          </div>
        )
      }



      {/* Leave/WFH table  */}
      <div className="leave-wfh-table-container custom-padding">
        <GeneralTable
          headers={leaveHeaders}
          tableData={currentTableData}
          currentPage={1}
          rowsPerPage={10}
          tableType="leave"
          onButtonCLick={handleConfirmPopup}
          showActionButtons={isManagerView}
        />
      </div>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup handleDelete={handleAction} buttonName={popupButtonName} status={popupStatus} message={popupText} hidePopup={hidePopup} isItConfimationPopup={isItConfimationPopup} />
      )}
    </>
  );
};

export default LeavesOverview;
