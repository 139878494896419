import React, { useEffect, useState } from 'react'
import './RolesTable.css'
import Breadcrumb from '../../common-components/breadcrumb/Breadcrumb'
import GeneralTable from '../../common-components/gen-table/GeneralTable'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRoles } from '../../redux/apiCalls'
import MiniLoader from '../../common-components/miniLoader/MiniLoader'
import CommonButton from '../../common-components/button/CommonButton'
import Popup from '../../common-components/popup/Popup'
import { userRequest } from '../../requestMethod'
import { PERMISSIONS } from '../../config'
import { addRoleFailure, addRoleStart, addRoleSuccess, deleteRoleFailure, deleteRoleStart, deleteRoleSuccess, updateRoleFailure, updateRoleStart, updateRoleSuccess } from '../../redux/roleRedux'
import { FaRegEdit } from 'react-icons/fa'
import { MdOutlineDelete } from 'react-icons/md'
import AddAndUpdateRolePopup from './popup/AddRolePopup'

const RolesTable = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllRoles(dispatch);
    }, [dispatch]);

    const { roles, isFetching } = useSelector((state) => state.role);
    const [currentTableData, setCurrentTableData] = useState(roles || [])

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoaderActive, setIsLoaderActive] = useState(false);
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [popupStatus, setPopupStatus] = useState(true);
    const [popupText, setPopupText] = useState("Data has been added");
    const [selectedRole, setSelectedRole] = useState(null); // State for the selected role to edit
    const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState(null)


    const rolesTableHeader = [
        {
            headerName: "Role Name",
            valueToBeRender: "name",
            className: "text-capitalize",
        },
        {
            headerName: "Employee",
            subHeaders: [
                { headerName: "Add", valueToBeRender: "add_employee" },
                { headerName: "Edit", valueToBeRender: "edit_employee" },
                { headerName: "Delete", valueToBeRender: "delete_employee" },
            ],
        },
        {
            headerName: "Project",
            subHeaders: [
                { headerName: "Add", valueToBeRender: "add_project" },
                { headerName: "Edit", valueToBeRender: "edit_project" },
                { headerName: "Delete", valueToBeRender: "delete_project" },
            ],
        },
        {
            headerName: "Client",
            subHeaders: [
                { headerName: "Add", valueToBeRender: "add_client" },
                { headerName: "Edit", valueToBeRender: "edit_client" },
                { headerName: "Delete", valueToBeRender: "delete_client" },
            ],
        },
        {
            headerName: "Status",
            valueToBeRender: "status",
            renderFunction: (value, rowData) => {
                return (
                    value === true ? "Active" : "Inactive"
                )
            }
        },
        {
            headerName: "Action",
            valueToBeRender: '_id',
            renderFunction: (value, rowData) => {
                return (
                    <div className='table-action-icons-wrapper'>
                        <FaRegEdit onClick={() => handleEditRole(rowData._id)} />
                        <MdOutlineDelete onClick={() => handleConfirmPopup(true, rowData._id)} />
                    </div>
                )
            }
        },
    ];

    const transformRoleData = (roles) =>
        roles?.map((role) => {
            const permissionsMap = PERMISSIONS?.reduce((acc, perm) => {
                acc[perm] = role.permissions.includes(perm);
                return acc;
            }, {});
            return { ...role, ...permissionsMap };
        });

    // const currentTableData = transformRoleData(roles);

    useEffect(() => {
        {
            setCurrentTableData(transformRoleData(roles));
        }
    }, [roles])

    const handleAddRolePopup = () => {
        setSelectedRole(null); // Clear the selected role for a new rolec
        setIsPopupOpen(true); // Open the popup
    };

    const handleEditRole = (roleId) => {
        const foundSelectedRole = roles?.find((role) => role._id == roleId)
        setSelectedRole(foundSelectedRole); // Set the selected role data
        setSelectedRoleId(roleId);
        setIsPopupOpen(true); // Open the popup
    };

    // const rolesTableHeader = ["Role Name", "Permissions"]; // Example headers

    // const handleAddRolePopup = () => {
    //     setIsPopupOpen(true); // Open the popup
    // };

    const handleClosePopup = () => {
        setIsPopupOpen(false); // Close the popup
    };

    const handleAddRole = async (newRole) => {
        // Simulate adding role (or call an API to add the role)
        setIsLoaderActive(true);
        dispatch(addRoleStart());
        try {
            const res = await userRequest.post('/role/create', newRole);
            if (res?.status === 200) {
                dispatch(addRoleSuccess(res.data))
                setIsLoaderActive(false);
                setIsPopupActive(true);
                setPopupStatus(true);
                setPopupText("Role has been added");
            }
        } catch (error) {
            dispatch(addRoleFailure());
            setIsLoaderActive(false);
            setIsPopupActive(true);
            setPopupStatus(false);
            setPopupText(error?.response?.data);
        }

        setIsPopupOpen(false); // Close the popup after submission

    };

    // Function to update the role  
    const handleUpdateRole = async (updatedRole) => {
        // Simulate adding role (or call an API to add the role)
        setIsLoaderActive(true);
        dispatch(updateRoleStart());
        try {
            const res = await userRequest.put(`/role/${selectedRoleId}`, updatedRole);
            if (res?.status === 200) {
                dispatch(updateRoleSuccess(res.data))
                setIsLoaderActive(false);
                setIsPopupActive(true);
                setPopupStatus(true);
                setPopupText("Role has been updated");
            }
        } catch (error) {
            dispatch(updateRoleFailure());
            setIsLoaderActive(false);
            setIsPopupActive(true);
            setPopupStatus(false);
            setPopupText(error?.response?.data);
        }

        setIsPopupOpen(false); // Close the popup after submission
    };

    const handleDeleteRole = () => {
        setIsItConfimationPopup(true)
    }

    const handleConfirmPopup = (popupStatus, id) => {
        setIsPopupActive(popupStatus);
        setIsItConfimationPopup(true);
        setSelectedRoleId(id);
    };


    const deleteRole = async (id, dispatch) => {
        setIsLoaderActive(true);
        setIsPopupActive(false);
        setIsItConfimationPopup(false);
        dispatch(deleteRoleStart());
        try {
            const res = await userRequest.delete(`/role/${id}`);
            if (res?.status === 200) {
                dispatch(deleteRoleSuccess(id));
                setIsLoaderActive(false);
                setIsItConfimationPopup(false);
                setIsPopupActive(true);
                setPopupStatus(true);
                setPopupText(res.data);
            }
        } catch (error) {
            dispatch(deleteRoleFailure());
            setIsLoaderActive(false);
            setIsItConfimationPopup(false);
            setIsPopupActive(true);
            setPopupStatus(false);
            setPopupText(error?.response?.data);
        }
    };

    const handleDelete = () => {
        deleteRole(selectedRoleId, dispatch);
    };

    const hidePopup = () => {
        setIsPopupActive(false);
    };

    if (isFetching || isLoaderActive) {
        return <MiniLoader />;
    }

    return (
        <>
            <Breadcrumb mainTitle="User Roles" />
            <div className="custom-padding">
                <div className="roles-table-button-wrapper p-2">
                    <CommonButton btnName="Add Role" btnClass="btn-edit" onClickAction={handleAddRolePopup} />
                </div>
                <GeneralTable headers={rolesTableHeader} tableData={currentTableData} />
            </div>
            {isPopupOpen && (
                <AddAndUpdateRolePopup
                    roleData={selectedRole}
                    onClose={handleClosePopup}
                    onAddRole={handleAddRole}
                    onUpdateRole={handleUpdateRole}
                />
            )}
            {/* {isLoaderActive && <MiniLoader />} */}
            {isPopupActive && (
                <Popup handleDelete={handleDelete} status={popupStatus} message={popupText} hidePopup={hidePopup} isItConfimationPopup={isItConfimationPopup} />
            )}
            {/* {isPopupActive && (
          <Popup
            status={popupStatus}
            message={popupText}
            isItConfimationPopup={isItConfimationPopup}
            handleDelete={handleDelete}
            hidePopup={hidePopup}
          />
        )} */}
        </>
    );
}

export default RolesTable
