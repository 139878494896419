import { Card, Col, Container, Row } from "react-bootstrap";
import "./EmployeeCard.css";
import { Link } from "react-router-dom";
import { FaCircleUser } from "react-icons/fa6";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import { userRequest } from "../../../requestMethod";
import { useDispatch } from "react-redux";
import Popup from "../../popup/Popup";
import { useState } from "react";
import MiniLoader from "../../miniLoader/MiniLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  deleteEmployeeFailure,
  deleteEmployeeStart,
  deleteEmployeeSuccess,
} from "../../../redux/employeeRedux";
import { hasPermission } from "../../../config";

const EmployeeCard = ({ details }) => {
  const isWorking = true;
  // console.log("details in employee card", details);
  
  const currentUser = useCurrentUser();
  // console.log( "details?.reportingTo", details?.reportingTo, "currentUser?._id", currentUser?._id)
  const dispatch = useDispatch();

  const [isPopupActive, setIsPopupActive] = useState(false);
  const [deletingEmployeeId, setDeletingEmployeeId] = useState(null);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const bulletColor = isWorking ? "green" : "red";

  const handleDropDownClick = (id) => {
    setActiveDropdown((prevState) => (prevState === id ? null : id));
  };

  const email = "abc@gmail.com";
  const phone = "9876543210";
  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${phone}`;
  };

  const setBgColorOfElements = (employeeStatus, elementType) => {
    const lowerCaseStatus = employeeStatus?.toLowerCase();
    const colorMap = {
      "full time": {
        empCodeBgColor: "var(--violet)",
        empDeptBgColor: "var(--LViolet)",
        empDeptName: "var(--violet)",
        phoneColor: "var(--violet)",
      },
      intern: {
        empCodeBgColor: "var(--violet)",
        empDeptBgColor: "var(--LViolet)",
        empDeptName: "var(--violet)",
        phoneColor: "var(--violet)",
      },
      "notice period": {
        empCodeBgColor: "var(--Orange)",
        empDeptBgColor: "var(--LOrange)",
        empDeptName: "var(--Orange)",
        phoneColor: "var(--Orange)",
      },
      former: {
        empCodeBgColor: "var(--Red)",
        empDeptBgColor: "var(--LRed)",
        empDeptName: "var(--Red)",
        phoneColor: "var(--Red)",
      },
      // Default colors if the status is unknown
      default: {
        empCodeBgColor: "var(--LGrey)",
        empDeptBgColor: "var(--LGrey)",
        empDeptName: "var(--LGrey)",
        phoneColor: "var(--LGrey)",
      },
    };
    return (
      colorMap[lowerCaseStatus]?.[elementType] || colorMap.default[elementType]
    );
  };

  const deleteEmployee = async (id, dispatch) => {
    setIsLoaderActive(true);
    setIsPopupActive(false);
    setIsItConfimationPopup(false);
    // dispatch(deleteEmployeeStart());
    try {
      const res = await userRequest.delete(`/employee/${id}`);
      if (res?.status === 200) {
        dispatch(deleteEmployeeSuccess(id));
        setIsLoaderActive(false);
        setIsItConfimationPopup(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res.data);
      }
    } catch (error) {
      // console.log("coming error ==>", error);
      // dispatch(deleteEmployeeFailure());
      setIsLoaderActive(false);
      setIsItConfimationPopup(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data);
    }
  };

  const hidePopup = () => {
    setIsPopupActive(false);
    setIsLoaderActive(false);
  };

  const handleDelete = () => {
    deleteEmployee(deletingEmployeeId, dispatch);
  };

  const handleConfirmPopup = (popupStatus, id) => {
    setIsPopupActive(popupStatus);
    setIsItConfimationPopup(true);
    setDeletingEmployeeId(id);
  };

  return (
    <section className="emp-card-wrapper custom-padding pt-0">
      <Container>
        <Row>
          {details?.map((detail, index) => {
            const empCodeBgColor = setBgColorOfElements(
              detail.employeeStatus,
              "empCodeBgColor"
            );

            const empDeptBgColor = setBgColorOfElements(
              detail.employeeStatus,
              "empDeptBgColor"
            );

            const empDeptName = setBgColorOfElements(
              detail.employeeStatus,
              "empDeptName"
            );

            const phoneColor = setBgColorOfElements(
              detail.employeeStatus,
              "phoneColor"
            );

            const reportingManagers = details?.filter(
              (emp) => emp._id === detail.reportingTo
            );
            return (
              <Col lg={4}>
                <Card className="emp-card">
                  {/* unique emp */}
                  <div
                    className="ec-uniqueNo-wrapper position-relative text-center"
                    style={{
                      backgroundColor: empCodeBgColor, padding: "15px"
                    }}
                  >
                    {/* <h6 className="m-0">{detail?.employeeId}</h6> */}
                    {
                      (hasPermission(currentUser, ["edit_employee", "delete_employee"])) && (
                        <>
                          <BsThreeDotsVertical onClick={() => handleDropDownClick(detail?._id)} />
                          {
                            activeDropdown === detail?._id && (
                              <div className="dropdown-optio-wrapper">
                                <ul>
                                  {hasPermission(currentUser, ["edit_employee"]) && (
                                    <Link to={`/edit-employee/${detail?._id}`}><li>Edit</li></Link>
                                  )}
                                  {hasPermission(currentUser, ["delete_employee"]) && (
                                    <Link onClick={() => handleConfirmPopup(true, detail._id)}><li>Delete</li></Link>
                                  )}
                                  {
                                    (detail?.reportingTo === currentUser?._id ||  (hasPermission(currentUser, []))) && (
                                      <>
                                        <Link to={`/employee-leave/${detail?._id}`} ><li>Leaves</li></Link>
                                        <Link to={`/employee-attendance/${detail?._id}`} ><li>Attendance</li></Link>
                                      </>
                                    )
                                  }
                                </ul>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                  </div>
                  {/* <div className="employee-working-status">
                    <p>Available</p>
                    <span
                      className={`bullet blink`}
                      style={{ backgroundColor: bulletColor }}
                    ></span>
                  </div> */}

                  <div className="emp-card-content-wrapper d-flex">
                    {/* Employee profile pic  */}
                    <div className="emp-img position-relative d-inline-block">
                      {/* <img
                        src={``}
                        alt=""
                      /> */}
                      <FaCircleUser
                        style={{ color: empCodeBgColor }}
                      ></FaCircleUser>
                      <span
                        className={`bullet blink`}
                        style={{ backgroundColor: bulletColor }}
                      ></span>

                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/user-imgs/${detail.empImg}`}
                        alt=""
                      /> */}
                    </div>

                    {/* employee details rest pfp & status  */}
                    <div className="emp-details-wrapper">
                      {/* employee name  */}
                      <div className="emp-name">
                        <p className="text-capitalize">{detail.name}</p>
                      </div>

                      {/* employee team  */}
                      <div
                        className="emp-team"
                        style={{
                          backgroundColor: empDeptBgColor,
                        }}
                      >
                        <p className="m-0" style={{ color: empDeptName }}>
                          {detail?.designation}
                        </p>
                      </div>

                      <div className="emp-dept mb-1">
                        <p className="m-0">{detail?.department}</p>
                      </div>

                      {/* employee contact details  */}
                      <div className="emp-email">
                        <Link to="#" onClick={handleEmailClick}>
                          <p>{detail.email}</p>
                        </Link>
                      </div>

                      <div className="emp-phone">
                        <Link
                          to="#"
                          onClick={handlePhoneClick}
                          style={{ color: phoneColor }}
                        >
                          <p>{detail.phone}</p>
                        </Link>
                      </div>
                    </div>

                    {/* reporting manager */}
                    {/* <div className="emp-rm">
                      <p className="m-0">
                        Reporting to - {detail.rManager}&nbsp;&nbsp;
                        <span className="rm-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/user-imgs/${detail.empImg}`}
                            alt=""
                          />
                        </span>
                      </p>
                    </div> */}
                  </div>

                  {/* employee status  */}
                  <div
                    className="emp-status text-center"
                    style={{
                      backgroundColor: empCodeBgColor,
                    }}
                  >
                    <h6 className="m-0">{detail?.employeeStatus}</h6>
                  </div>
                </Card>
                {/* {
                  currentUser?.isUserUniworldAdmin && (
                    <div className="action-btn-wrapper">
                      <Link to={`/edit-employee/${detail?._id}`}>
                        <CommonButton btnName="Edit" btnClass='btn-edit bg-orange' />
                      </Link>
                      <CommonButton btnName="Delete" btnClass='btn-edit bg-red' onClickAction={() => handleConfirmPopup(true, detail._id)} />
                    </div>
                  )
                } */}
              </Col>
            );
          })}
        </Row>
        {isLoaderActive && <MiniLoader />}
        {isPopupActive && (
          <Popup
            status={popupStatus}
            message={popupText}
            isItConfimationPopup={isItConfimationPopup}
            handleDelete={handleDelete}
            hidePopup={hidePopup}
          />
        )}
      </Container>
    </section>
  );
};

export default EmployeeCard;
