import { createSlice } from "@reduxjs/toolkit";

const clientReducer = createSlice({
    name: 'client',
    initialState: {
        clients: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getClientsStart: (state) => {
            state.isFetching = true;
        },
        getClientsSuccess: (state, action) => {
            state.isFetching = false;
            state.clients = action.payload;
        },
        getClientsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add client actions 
        addClientStart: (state) => {
            state.isFetching = true;
        },

        addClientSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.clients = [...state.clients, newUser];
        },

        addClientFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteClientStart: (state) => {
            // state.isFetching = true;
        },

        deleteClientSuccess: (state, action) => {
            state.isFetching = false;
            state.clients.splice(
                state.clients.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteClientFailure: (state) => {
            // state.isFetching = false;
            state.error = true;
        },

        // Update client actions
        updateClientStart: (state) => {
            state.isFetching = true;
        },

        updateClientSuccess: (state, action) => {
            state.isFetching = false;
            const clientIndex = state.clients.findIndex(client => client._id === action.payload._id);
            if (clientIndex !== -1) {
                state.clients[clientIndex] = action.payload;
            }
        },

        updateClientFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // update user password actions
        updatePasswordStart : (state) => {
            state.isFetching = true;
            state.error = false;
        },

        updatePasswordSuccess : (state, action) => {
            state.isFetching = false;
            state.clients = action.payload;
        },
        updatePasswordFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getClientsStart, getClientsSuccess, getClientsFailure, deleteClientStart, deleteClientSuccess, deleteClientFailure, addClientFailure, addClientStart, addClientSuccess, updateClientStart, updateClientSuccess, updateClientFailure, updatePasswordStart, updatePasswordSuccess, updatePasswordFailure } = clientReducer.actions;
export default clientReducer.reducer;

