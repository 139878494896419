import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../sign-in/signIn.css";
import { Col, Container, Row } from "react-bootstrap";
import CommonButton from "../../common-components/button/CommonButton";
import { Link, useNavigate } from "react-router-dom";
import { publicRequest } from "../../requestMethod";
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userRedux";
import Cookies from "js-cookie";
import { validateRequired } from "../../formValidation";
import MiniLoader from "../../common-components/miniLoader/MiniLoader";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userNotFoundError, setUserNotFoundError] = useState(false);
  const [userNotFoundErrorMessage, setUserNotFoundErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isForgotPasswordFormVisible, setIsForgotPasswordFormVisible] =
    useState(false);
  const [isSettingPasswordVisible, setIsSettingPasswordVisible] =
    useState(false);
  const [isSignInFormVisible, setIsSignInFormVisible] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState(null);
  const [isEnteredOtpCorrect, setIsEnteredOtpCorrect] = useState(true);
  const [newPassword, setNewPassword] = useState(null);
  const [confimNewPassword, setConfimNewPassword] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    useState("");
  const [isUserHavePassword, setIsUserHavePassword] = useState(false);
  // const [isUserNotFoundError, setIsUserNotFoundError] = useState(false);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormType = (type) => {
    if (type === "signInForm") {
      setIsSignInFormVisible(true);
      setIsForgotPasswordFormVisible(false);
    }
    if (type === "forgotPasswordForm") {
      setIsSignInFormVisible(false);
      setIsForgotPasswordFormVisible(true);
    }
  };

  const login = async (dispatch, user) => {
    dispatch(loginStart());
    setIsLoaderActive(true);
    try {
      const res = await publicRequest.post("/auth/login", user);
      if (res?.status === 201) {
        dispatch(loginSuccess(res.data));
        setIsLoaderActive(false);
        // Cookies.set("user", res.data.accessToken, { expires: 3 });
        localStorage.setItem("popupDisplayed", "true");
        Cookies.set('user', res.data.accessToken, { domain: '.uniworldstudios.com', expires: 3, sameSite: 'None', secure: true});
        <Navigate to="/" />;
      }
    } catch (error) {
      setIsLoaderActive(false);
      dispatch(loginFailure());
      if (error?.response) {
        if (error.response?.status === 404) {
          setUserNotFoundError(true);
          setUserNotFoundErrorMessage(error.response.data);
        }

        if (error.response.status === 401) {
          setPasswordError(true);
          setPasswordErrorMessage(error.response.data);
        }

        if (error.response.status === 406) {
          setIsSettingPasswordVisible(true);
          setIsSignInFormVisible(false);
        }

        if (error.response.status === 428) {
          setIsUserHavePassword(true);
        }
      }
    }
  };

  const getOtpToResetPassword = async (email) => {
    setIsLoaderActive(true);
    try {
      const res = await publicRequest.post("/auth/forgot-password", {
        email: email,
      });
      if (res?.status === 200) {
        setIsOtpSent(true);
        setIsLoaderActive(false);
        setUserNotFoundError(false);
      }
    } catch (error) {
      setIsLoaderActive(false);
      if (error.response.status === 404) {
        setUserNotFoundError(true);
        setUserNotFoundErrorMessage(error.response.data);
      }
    }
  };

  const submitNewPassword = async (data) => {
    setIsLoaderActive(true);
    try {
      const res = await publicRequest.post("/auth/reset-password", data);
      if (res.status === 200) {
        setResetPasswordSuccess(true);
        setIsLoaderActive(false);
        setIsSignInFormVisible(true);
        setIsForgotPasswordFormVisible(false);
        setIsSettingPasswordVisible(false);
      }
    } catch (error) {
      setIsLoaderActive(false);
      if (error?.response?.status === 400) {
        setResetPasswordError(true);
        setResetPasswordErrorMessage(error?.response?.data);
      }
      setResetPasswordSuccess(false);
    }
  };

  // Validate function for form validation start=======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const emailValidErr = validateRequired(email, "email");
    const passwordValidErr = validateRequired(password);
    const newPasswordValidErr = validateRequired(newPassword);
    const confimNewPasswordValidErr = validateRequired(confimNewPassword);
    const otpValidErr = validateRequired(otp);

    if (emailValidErr && !isOtpSent) {
      formIsValid = false;
      newErrors.email = "Please enter your email";
    }
    if (passwordValidErr && isSignInFormVisible && isUserHavePassword) {
      formIsValid = false;
      newErrors.password = "Please enter your password";
    }
    if (newPasswordValidErr && isOtpSent) {
      formIsValid = false;
      newErrors.newPassword = "Please enter new password";
    }
    if (confimNewPasswordValidErr && isOtpSent) {
      formIsValid = false;
      newErrors.confimNewPassword = "Please enter new password again";
    }

    if (newPassword !== confimNewPassword && isOtpSent) {
      formIsValid = false;
      newErrors.confimNewPassword = "Password did not matched";
    }

    if (otpValidErr && isOtpSent) {
      formIsValid = false;
      newErrors.otp = "Please enter otp";
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleLogin = async (e) => {
    if (validateForm()) {
      setUserNotFoundError(false);
      setPasswordError(false);
      login(dispatch, { email, password });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && validateForm()) {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleGetOtp = () => {
    if (validateForm()) {
      getOtpToResetPassword(email);
    }
  };

  const handleResetPassword = () => {
    if (validateForm()) {
      submitNewPassword({ newPassword, email, otp });
    }
  };

  // if(isLoaderActive){
  //   return(
  //     <MiniLoader />
  //   )
  // }

  return (
    <div className="sign-in-wrapper ms-lg-5">
      <section className="sign-in-pg">
        <Container>
          <Row className="align-items-center gx-lg-5">
            <Col lg={7}>
              <div className="signup-pg-img sign-in-pg-img">
                <img
                  src={`${process.env.PUBLIC_URL}/images/sign-up/signIn-pg.png`}
                  alt=""
                />
              </div>
            </Col>
            <Col lg={5}>
              <div className="signin-wrapper">
                {isSignInFormVisible && (
                  <>
                    <div className="org-logo text-center mb-4">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/logo/uniworld-logo.png`}
                        alt=""
                      />
                    </div>
                    <div className="signin-head text-center">
                      <p>Hi, Welcome to your Virtual Workspace</p>
                    </div>
                    <div className="signin-form-head">
                      <h2>Sign In</h2>
                    </div>
                    {resetPasswordSuccess && (
                      <p>Your password has been updated.</p>
                    )}
                    <form className="signin-form-wrapper">
                      <input
                        type="text"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) =>
                          !isUserHavePassword && handleKeyPress(e)
                        }
                      />
                      <div className="error-msg">{errors.email}</div>
                      {userNotFoundError && (
                        <p className="error-text text-danger">
                          {userNotFoundErrorMessage}
                        </p>
                      )}
                      {isUserHavePassword && (
                        <>
                          <input
                            type="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyPress}
                          />
                          <div className="error-msg">{errors.password}</div>
                          {passwordError && (
                            <p className="error-text text-danger">
                              {passwordErrorMessage}
                            </p>
                          )}
                        </>
                      )}

                      <CommonButton
                        btnClass="primary-btn signin-form-btn"
                        btnName="Submit"
                        onClickAction={handleLogin}
                      />
                    </form>
                    {/* Register or change Password */}
                    <div className="reg-change-pwd">
                      {/* <p>
                        <Link to="/sign-up">Register</Link>
                      </p> */}
                      <button
                        className="link-button"
                        onClick={() => handleFormType("forgotPasswordForm")}
                      >
                        Forgot Password?
                      </button>
                      <Link to="/sign-up" className="link-button">
                        Sign Up
                      </Link>
                    </div>
                  </>
                )}
                {(isForgotPasswordFormVisible || isSettingPasswordVisible) && (
                  <>
                    <div className="signin-form-head">
                      {isSettingPasswordVisible && (
                        <>
                          <h2>Set Password </h2>
                          {!isOtpSent && (
                            <p className="error-text text-danger">
                              {/* {userNotFoundErrorMessage} */}
                              You have not set any password yet
                            </p>
                          )}
                        </>
                      )}
                      {isForgotPasswordFormVisible && <h2>Forgot Password</h2>}
                    </div>
                    <form className="signin-form-wrapper">
                      {/* {
                          isOtpSent && (
                            <p>We've sent an OTP on your email.</p>
                          )
                        } */}
                      {/* {userNotFoundError && (
                          <p className="error-text text-danger">
                            {userNotFoundErrorMessage}
                          </p>
                        )} */}
                      {isOtpSent && (
                        <>
                          <input
                            type="text"
                            placeholder="Enter your email"
                            disabled={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {/* {
                                resetPasswordError && (
                                  <p>{resetPasswordErrorMessage}</p>
                                )
                              } */}
                          <p className="mt-3">
                            We've sent an OTP on your email.
                          </p>
                          <input
                            type="text"
                            placeholder="Enter otp here"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <div className="error-msg">{errors.otp}</div>
                          {resetPasswordError && (
                            <div className="error-msg mb-1">
                              {resetPasswordErrorMessage}
                            </div>
                          )}
                          <input
                            type="text"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <div className="error-msg">{errors.newPassword}</div>
                          <input
                            type="text"
                            placeholder="Confirm Password"
                            value={confimNewPassword}
                            onChange={(e) =>
                              setConfimNewPassword(e.target.value)
                            }
                          />
                          <div className="error-msg">
                            {errors.confimNewPassword}
                          </div>
                          {/* <div className="error-msg">{errors.password}</div>
                              {passwordError && (
                                <p className="error-text text-danger">
                                  {passwordErrorMessage}
                                </p>
                              )} */}
                          <CommonButton
                            btnClass="primary-btn signin-form-btn"
                            btnName={"Submit OTP"}
                            onClickAction={handleResetPassword}
                          />
                        </>
                      )}
                      {/* {
                          isEnteredOtpCorrect && (
                            <>
                              <div className="error-msg">{errors.password}</div>
                              {passwordError && (
                                <p className="error-text text-danger">
                                  {passwordErrorMessage}
                                </p>
                              )}
                              <CommonButton
                                btnClass="primary-btn signin-form-btn"
                                btnName={'Submit OTP'}
                                onClickAction={handleResetPassword}
                              />
                            </>
                          )
                        } */}
                      {!isOtpSent && (
                        <>
                          <input
                            type="text"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {userNotFoundError && (
                            <p className="error-text text-danger">
                              {userNotFoundErrorMessage}
                            </p>
                          )}
                          <div className="error-msg">{errors.email}</div>
                          <CommonButton
                            btnClass="primary-btn signin-form-btn"
                            btnName={"Get OTP"}
                            onClickAction={handleGetOtp}
                          />
                        </>
                      )}
                    </form>
                    {/* Register or change Password */}
                    <div className="reg-change-pwd">
                      {/* <p>
                        <Link to="/sign-up">Register</Link>
                      </p> */}
                      {isForgotPasswordFormVisible ? (
                        <button
                          className="link-button"
                          onClick={() => handleFormType("signInForm")}
                        >
                          Sign In
                        </button>
                      ) : (
                        <div className="fp-btn-signup-btns-wrapper">
                          <button
                            className="link-button"
                            onClick={() => handleFormType("forgotPasswordForm")}
                          >
                            Forget Password
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {isLoaderActive && <MiniLoader />}
    </div>
  );
};

export default SignIn;
