import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DynamicForm from "../../../common-components/form/DynamicForm";
import { departmentName, designationName, newEmployeeTypes, oldEmployeeTypes, serverRequestType } from "../../../config";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import { getAllEmployees, getAllRoles } from "../../../redux/apiCalls";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";

const UpdateEmployee = () => {
  const { id: employeeId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const isUserFetching = useSelector((state) => state.employee?.isFetching);
  const currentEmployee = useSelector((state) =>
    state.employee?.employees?.find((employee) => employee?._id === employeeId)
  );


  const dispatch = useDispatch();

  useEffect(() => {
    getAllRoles(dispatch)
  }, [dispatch]);

  useEffect(() => {
    getAllEmployees(dispatch)
  }, [dispatch]);



  const { roles, isRolesFetching } = useSelector((state) => state.role);
  const { isFetching: isEmployeesFetching, employees: allEmployees } = useSelector((state) => state?.employee);

  const oldEmployeeSchema = [
    {
      name: "name",
      type: "text",
      label: "Name",
      placeholder: "Enter your Name",
      validation: "required",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      placeholder: "Enter your Email",
      validation: "required|email",
    },
    {
      name: "phone",
      type: "text",
      label: "Phone Number",
      placeholder: "Enter your Contact Number",
      validation: "required",
    },
    {
      name: "dateOfBirth",
      type: "date",
      label: "Date of Birth",
      validation: "required",
    },
    {
      name: "department",
      type: "dropdown",
      label: "Select Department",
      options: departmentName,
      validation: "required",
    },
    {
      name: "designation",
      type: "conditionalDropdown",
      label: "Designation",
      options: designationName,
      placeholder: "Enter Employee Designation",
      validation: "required",
    },
    {
      name: "employeeStatus",
      type: "dropdown",
      label: "Select Employee Status",
      options: oldEmployeeTypes,
      validation: "required",
    },
    {
      name: "role",
      type: "dropdownWithArrayObjectOption",
      label: "Select Employee Role",
      options: roles,
      valueToBeRender: "name",
      // validation: "required",
    },
    {
      name: "reportingTo",
      type: "dropdownWithArrayObjectOption",
      label: "Reporting To",
      options: allEmployees,
      valueToBeRender: "name",
      showNullValue : true,
      nullValueTitle : "Not reporting",
      valueToBeStoredForNullValue : null
    },
  ];

  useEffect(() => {
    setInitialValues(currentEmployee);
  }, [currentEmployee]);

  if (isRolesFetching || isEmployeesFetching) {
    return <MiniLoader />
  }

  return (
    // <>
    // {
    initialValues ? (
      <>
        {/* breadcrumb  */}
        <Breadcrumb mainTitle="Update Details" />

        {/* employee details update form */}
        <div className="emp-detail-update-form custom-padding">
          <DynamicForm
            schema={oldEmployeeSchema}
            initialValues={initialValues}
            httpMethod={serverRequestType.PUT}
            apiEndPoint={`employee/${employeeId}`}
            buttonName="Update"
          />
        </div>
      </>
    ) : (
      <h1>Loading....</h1>
    )
  );
};

export default UpdateEmployee;
