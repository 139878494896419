import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import "./LeaveWFHForm.css";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import { addDays, format } from "date-fns";
import { userRequest } from "../../../../requestMethod";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import Popup from "../../../../common-components/popup/Popup";
import CommonButton from "../../../../common-components/button/CommonButton";
import { useNavigate } from "react-router-dom";

const LeaveWFHForm = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [days, setDays] = useState([]);
  const [reason, setReason] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Data has been added");
  const [successMessage, setSuccessMessage] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const dayArray = [];

      for (let d = start; d <= end; d = addDays(d, 1)) {
        const dayOfWeek = d.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // Exclude Sundays (0) and Saturdays (6)
          dayArray.push({
            date: format(d, "yyyy-MM-dd"),
            nameOfTheDay: format(d, "EEEE"),
            typeOfLeave: "",
          });
        }
      }

      setDays(dayArray);
    } else {
      setDays([]);
    }
  }, [startDate, endDate]);

  const today = new Date().toISOString().split("T")[0]; // Today's date in 'YYYY-MM-DD' format

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    if (name === "startDate") {
      setStartDate(value);

      // Ensure the end date is after the start date, otherwise reset the end date
      if (endDate && value > endDate) {
        setEndDate("");
      }
    } else if (name === "endDate") {
      setEndDate(value);

      // Ensure the start date is before the end date
      if (startDate && value < startDate) {
        setStartDate("");
      }
    }
  };

  const handleTypeChange = (e, index) => {
    const newDays = [...days];
    newDays[index].typeOfLeave = e.target.value;
    setDays(newDays);
  };

  const handleSubmit = async (data) => {
    setIsLoaderActive(true);
    try {
      const res = await userRequest.post("/leave/create", data);
      if (res?.status === 200) {
        // <Navigate to="/leaves-overview" />
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res?.data?.message);
        // setStartDate(null);
        // setEndDate(null);
        // setDays([]);
        // setReason("")

      }
    } catch (error) {
      setIsLoaderActive(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data);
    }
  };

  const sendData = () => {
    handleSubmit({ startDate, endDate, days, reason });
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Leaves" />

      <section className="leave-form-type-wrapper custom-padding">
        {/* <DynamicForm /> */}
        <Form>
          <Container>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    name="startDate"
                    type="date"
                    value={startDate}
                    onChange={handleDateChange}
                    min={today} // Disallow past dates
                    max={endDate || ""} // Ensure start date is not after end date
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={handleDateChange}
                    min={startDate || today} // Ensure end date is not before start date or today
                  />
                </FormGroup>
              </Col>
            </Row>
            {days.length > 0 && (
              <Row>
                <h5 className="pt-4">Select Leave Type for Each Day</h5>
                {days?.map((day, index) => (
                  <Col lg={4} >
                    <FormGroup key={index} >
                      <Form.Label>{`${day.nameOfTheDay}, ${day.date}`}</Form.Label>
                      <Form.Control
                        as="select"
                        value={day.typeOfLeave}
                        onChange={(e) => handleTypeChange(e, index)}
                      >
                        <option value="" disabled>
                          Select Leave Type
                        </option>
                        <option value="work_from_home">Work From Home</option>
                        <option value="half_day">Half Day</option>
                        <option value="full_day">Full Day</option>
                      </Form.Control>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Form.Label class="reason-label">Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="reason"
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <CommonButton
              onClickAction={sendData}
              btnName="Apply"
              btnClass="btn-apply common-btn"
            />
          </Container>
        </Form>
      </section>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup status={popupStatus} message={popupText} hidePopup={hidePopup} />
      )}
      {/* {renderLeaveForm()} */}
    </>
  );
};

export default LeaveWFHForm;
