import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import CommonButton from "../../../../common-components/button/CommonButton";
import { IoIosAddCircle } from "react-icons/io";
import "./NotesEditor.css";
import { IoSave } from "react-icons/io5";

const NotesEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [note, setNote] = useState({
    id: Date.now(),
    title: "",
    content: "",
    color: "#EDEDED",
    date: new Date().toLocaleDateString(),
  });

  useEffect(() => {
    if (id) {
      const savedNotes = JSON.parse(localStorage.getItem("notes")) || [];
      const existingNote = savedNotes.find((n) => n.id === parseInt(id));
      if (existingNote) {
        setNote(existingNote);
      }
    }
  }, [id]);

  const handleColorChange = (color) => {
    setNote((prev) => ({ ...prev, color }));
  };

  const handleSave = () => {
    const savedNotes = JSON.parse(localStorage.getItem("notes")) || [];

    if (id) {
      // Update Existing Note
      const updatedNotes = savedNotes.map((n) =>
        n.id === parseInt(id) ? note : n
      );
      localStorage.setItem("notes", JSON.stringify(updatedNotes));
    } else {
      // Add New Note
      const updatedNotes = [...savedNotes, note];
      localStorage.setItem("notes", JSON.stringify(updatedNotes));
    }

    navigate("/all-notes");
  };

  return (
    <div className="note-editor-wrapper">
      {/* breadcrumb  */}
      <Breadcrumb mainTitle={id ? "Edit Note" : "New Note"} />

      <div className="p-6 w-full max-w-3xl mx-auto custom-padding">
        <div className="save-note-color-btn d-flex justify-content-end column-gap-4">
          {/* color btns  */}
          <div className="color-btns-wrapper d-flex align-items-center column-gap-2">
            {["#E9E4FF", "#FFEBD3", "#E7F7C6"].map((color) => (
              <div
                key={color}
                className={`color-btn ${
                  note.color === color ? "border-black" : "border-transparent"
                }`}
                style={{ backgroundColor: color }}
                onClick={() => handleColorChange(color)}
                
              />
            ))}
          </div>

          <CommonButton
            btnName={
              <>
                Save &nbsp; <IoSave />
              </>
            }
            btnClass="btn-edit new-icon-btn"
            onClickAction={handleSave}
          />
        </div>

        <div className="position-relative pt-4 d-flex flex-column">
          <input
            type="text"
            placeholder="Title"
            value={note.title}
            onChange={(e) =>
              setNote((prev) => ({ ...prev, title: e.target.value }))
            }
            className="note-editor-title w-full"
          />

          <textarea
            placeholder="Write your note here..."
            value={note.content}
            onChange={(e) =>
              setNote((prev) => ({ ...prev, content: e.target.value }))
            }
            className="note-main-body"
          />
        </div>
      </div>
    </div>
  );
};

export default NotesEditor;
