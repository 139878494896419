import React, { useEffect, useState } from "react";
import "./Notification.css";
import { formatRelativeTime, iconMap } from "../../../config";
import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";


const Notification = ({ handleMarkSingleAsRead, notification }) => {
  const [formattedTime, setFormattedTime] = useState(formatRelativeTime(notification?.createdAt));

  useEffect(() => {
    // Update the time every 60 seconds
    const interval = setInterval(() => {
      setFormattedTime(formatRelativeTime(notification?.createdAt));
    }, 60000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [notification?.createdAt]);




  return (
    <Link to={notification?.redirectUrl ? notification?.redirectUrl : '/'} style={{ color: "initial" }}>
      <div className={` ${notification?.isSeen ? "bg-white" : ""} notification-item-wrapper d-flex align-items-center justify-content-between`}>
        <div className="noti-item-icon-img-wrapper">
          <div className={` ${notification?.isSeen ? "bg-grey" : ""} notification-icon`}>
            {iconMap[notification?.type] || <div className="default-icon">?</div>}
          </div>
        </div>
        <div className="notification-content-wrapper">
          <div className="main-noti-content">
            <h6 className="m-0">{notification?.title}</h6>
            <p className="m-0">{notification?.description}</p>
          </div>
          <div className="notification-time">
            {/* <p className="m-0">22 min ago</p> */}
            <p className="m-0">{formattedTime}</p>
          </div>
        </div>
        <div className="remove-icon-wrapper">
          <MdCancel className="clickable" onClick={() => handleMarkSingleAsRead(notification?._id)} />
        </div>
      </div>
    </Link>
  );
};

export default Notification;
