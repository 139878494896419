import React, { useState } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import './DropDownButton.css'

const DropDownButton = ({ label, options, onSelect, selected }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(label);

    const handleToggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option.label || option);
        setIsDropdownVisible(false);
        onSelect(option.value || option); // Pass selected value to parent
    };

    return (
        <div className="manage-dropdown">
            <button className="btn-edit text-capitalize new-small-btn" onClick={handleToggleDropdown}>
                {/* {selectedOption} */}
                {selected ? selected : label}
                {isDropdownVisible ? <FaAngleUp className="m-0" /> : <FaAngleDown className="m-0" />}
            </button>
            {isDropdownVisible && (
                <ul className="dropdown-list">
                    {options?.map((option, index) => (
                        <li
                            key={index}
                            className="dropdown-item text-capitalize text-start"
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option?.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropDownButton;
