import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./HeaderNav.css";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import {
  MdAccessible,
  MdNotificationsActive,
  MdOutlineTask,
  MdOutlineVideoCameraFront,
} from "react-icons/md";
import { GrOverview, GrProjects, GrUserSettings } from "react-icons/gr";
import { FaPeopleRoof } from "react-icons/fa6";
import { RiUserStarLine } from "react-icons/ri";
import { BsCalendar2X, BsTicketDetailed } from "react-icons/bs";
import { FiUserCheck } from "react-icons/fi";
import { TbReport } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import { CgUserList, CgGitPull } from "react-icons/cg";
import { GoSignOut } from "react-icons/go";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { LuArrowLeftToLine } from "react-icons/lu";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { logoutUser } from "../../../redux/userRedux";
import { persistor } from "../../../redux/store";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import { LiaStickyNoteSolid } from "react-icons/lia";

const HeaderNav = ({ menuCollapsed, setMenuCollapsed }) => {
  const menuIcon = () => {
    if (window.innerWidth >= 767) {
      setMenuCollapsed((prevState) => !prevState);
    }
  };

  const currentUser = useCurrentUser();

  let currentPading = menuCollapsed ? "collapsePadding" : "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("persist:root");
    Cookies.remove("userIn");
    persistor.purge().then(() => {
      dispatch(logoutUser());
    });
    navigate("/", { replace: true });
  };

  return (
    <div className="sidebar-main-wrapper">
      <Sidebar collapsed={menuCollapsed} className="sidebar-inner-wrapper">
        <div className="sidebar-header-menu">
          <div
            className="Sidebar-header"
            style={{
              "justify-content": menuCollapsed ? "center" : "space-between",
            }}
          >
            {/* greetings and Username */}
            {!menuCollapsed && (
              <div className="greeting-name">
                <h5 className="m-0 text-capitalize">{`Welcome!`}</h5>
              </div>
            )}

            <div className="collapse-icon" onClick={menuIcon}>
              {menuCollapsed ? <AiOutlineMenuUnfold /> : <LuArrowLeftToLine />}
            </div>
          </div>

          <Menu className="custom-scrollbar custom-sidebar-db sidebar-list">
            <MenuItem
              icon={<GrOverview />}
              component={<Link to="/emp-overview" className={currentPading} />}
            >
              Overview
            </MenuItem>
            {currentUser?.userType === "organisation-owner" && (
              <MenuItem
                icon={<MdNotificationsActive />}
                component={<Link to="/global-notification" />}
              >
                Global Notification
              </MenuItem>
            )}
            {currentUser?.userType === "organisation-owner" && (
              <MenuItem
                icon={<MdAccessible />}
                component={<Link to="/user-role" className={currentPading} />}
              >
                User Roles
              </MenuItem>
            )}

            <MenuItem
              icon={<CgUserList />}
              component={<Link to="/employees" className={currentPading} />}
            >
              Employees
            </MenuItem>
            <MenuItem
              icon={<FaPeopleRoof />}
              component={<Link to="/teams" className={currentPading} />}
            >
              Teams
            </MenuItem>
            <MenuItem
              icon={<RiUserStarLine />}
              component={<Link to="/clients" className={currentPading} />}
            >
              Clients
            </MenuItem>
            <MenuItem
              icon={<GrProjects />}
              component={<Link to="/all-projects" className={currentPading} />}
            >
              Projects
            </MenuItem>
            <MenuItem
              icon={<MdOutlineTask />}
              component={<Link to="/all-tasks" className={currentPading} />}
            >
              Tasks
            </MenuItem>
            <MenuItem
              icon={<BsCalendar2X />}
              component={
                <Link to="/leaves-overview" className={currentPading} />
              }
            >
              Leaves / WFH
            </MenuItem>
            <MenuItem
              icon={<FiUserCheck />}
              component={<Link to="/attendance" className={currentPading} />}
            >
              Attendance
            </MenuItem>

            <MenuItem
              icon={<LiaStickyNoteSolid />}
              component={<Link to="/all-notes" className={currentPading} />}
            >
              Notes
            </MenuItem>
            {/* <MenuItem
              icon={<TbReport />}
              component={<Link to="#" className={currentPading} />}
            >
              Reports
            </MenuItem> */}
            {/* <MenuItem
              icon={<SlCalender />}
              component={<Link to="/calender" className={currentPading} />}
            >
              Calender
            </MenuItem> */}
            {/* <MenuItem
              icon={<BsTicketDetailed />}
              component={<Link to="/tickets" className={currentPading} />}
            >
              Tickets
            </MenuItem> */}
            <MenuItem
              icon={<MdOutlineVideoCameraFront />}
              component={<Link to="/all-meetings" className={currentPading} />}
            >
              Meetings
            </MenuItem>
            {/* <MenuItem
              icon={<CgGitPull />}
              component={<Link to="#" className={currentPading} />}
            >
              Referrals
            </MenuItem> */}
            {/* <MenuItem
              icon={<GrUserSettings />}
              component={
                <Link to="/account-setting" className={currentPading} />
              }
            >
              Account Setting
            </MenuItem> */}
            <MenuItem
              icon={<GoSignOut />}
              onClick={handleLogout}
              component={<Link to="" className={currentPading} />}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};

export default HeaderNav;
