import { createSlice } from "@reduxjs/toolkit";

const clientReducer = createSlice({
    name: 'project',
    initialState: {
        projects: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get project actions
        getProjectsStart: (state) => {
            state.isFetching = true;
        },
        getProjectsSuccess: (state, action) => {
            state.isFetching = false;
            state.projects = action.payload;
        },
        getProjectsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add project actions 
        addProjectStart: (state) => {
            state.isFetching = true;
        },

        addProjectSuccess: (state, action) => {
            state.isFetching = false;
            const newProject = action.payload;
            state.projects = [...state.projects, newProject];
        },

        addProjectFailure: (state) => {
            state.error = true;
        },

        // Delete project actions
        deleteProjectStart: (state) => {
            state.isFetching = true;
        },

        deleteProjectSuccess: (state, action) => {
            state.isFetching = false;
            state.projects.splice(
                state.projects.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteProjectFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        deleteProjectTaskSuccess: (state, action) => {
            state.isFetching = false;
            const { projectId, taskIndex } = action.payload;

            // Find the index of the project
            const projectIndex = state.projects.findIndex(project => project._id === projectId);
            if (projectIndex !== -1) {
                // Check if taskIndex is valid
                if (taskIndex >= 0 && taskIndex < state.projects[projectIndex].tasks.length) {
                    // Remove the task from the tasks array
                    state.projects[projectIndex].tasks.splice(taskIndex, 1);
                }
            }
        },

        // Update project actions
        updateProjectStart: (state) => {
            state.isFetching = true;
        },

        updateProjectSuccess: (state, action) => {
            state.isFetching = false;
            const projectIndex = state.projects.findIndex(project => project._id === action.payload._id);
            if (projectIndex !== -1) {
                state.projects[projectIndex] = action.payload;
            }
        },

        updateProjectFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // updateTask: (state, action) => {
        //     const { projectId, task } = action.payload;
        //     const project = state.projects.find(p => p._id === projectId);
        
        //     console.log("🔍 Current Redux State Before Update:");
        //     console.log("State projects:", JSON.parse(JSON.stringify(state.projects))); // Log entire projects array
        //     console.log("Looking for project ID:", projectId);
        
        //     if (project) {
        //         console.log("✅ Project found:", JSON.parse(JSON.stringify(project))); // Log project details
        
        //         console.log("Project Tasks Before Update:", JSON.parse(JSON.stringify(project.tasks))); // Log tasks array
        
        //         if (task.isDeleted) {
        //             console.log("🗑️ Removing task:", task._id);
        //             project.tasks = project.tasks.filter(t => t._id !== task._id);
        //         } else {
        //             console.log("✏️ Updating task:", task._id);
        //             const taskIndex = project.tasks.findIndex(t => t._id === task._id);
                    
        //             if (taskIndex !== -1) {
        //                 console.log("✅ Task found to update:", JSON.parse(JSON.stringify(project.tasks[taskIndex])));
        //                 project.tasks[taskIndex] = task;
        //             } else {
        //                 console.log("❌ Task NOT found to update:", task._id);
        //                 console.log("Project Tasks After Search:", JSON.parse(JSON.stringify(project.tasks)));
        //             }
        //         }
        //     } else {
        //         console.log("❌ Project not found:", projectId);
        //     }
        // },
        

        updateTask: (state, action) => {
            const { projectId, task } = action.payload;
            const project = state.projects.find(p => p._id === projectId);
            
            if (project) {
                if (task.isDeleted) {
                    project.tasks = project.tasks.filter(t => t._id !== task._id);
                } else {
                    const taskIndex = project.tasks.findIndex(t => t._id === task._id);
                    if (taskIndex !== -1) {
                        project.tasks[taskIndex] = task;
                    }
                }
            }
        },
        

        // updateTask: (state, action) => {
        //     const projectIndex = state.projects.findIndex(project => project._id === action.payload.projectId);
        //     console.log("Updating task:", action.payload.task);
        //     console.log("Redux state before update:", JSON.parse(JSON.stringify(state)));
        //     console.log("project index ==>", projectIndex)

        //     if (projectIndex !== -1) {
        //         if (action.payload.task.isDeleted) {
        //             state.projects[projectIndex] = {
        //                 ...state.projects[projectIndex],
        //                 tasks: state.projects[projectIndex].tasks.filter(task => task._id !== action.payload.task._id)
        //             };
        //         } else {
        //             console.log("coming into else block ===>")
        //             const updatedTasks = state.projects[projectIndex].tasks.map(task =>
        //                 task._id === action.payload.task._id ? action.payload.task : task
        //             );
        //             state.projects[projectIndex] = {
        //                 ...state.projects[projectIndex],
        //                 tasks: updatedTasks
        //             };
        //         }
        //     }
        // },


        // updateTask: (state, action) => {
        //     console.log("current state ==>", state.projects.find(project => project._id === action.payload.projectId));

        //     const projectIndex = state.projects.findIndex(project => project._id === action.payload.projectId);
        //     if (projectIndex !== -1) {
        //         const taskIndex = state.projects[projectIndex].tasks.findIndex(task => task._id === action.payload.task._id);

        //         if (taskIndex !== -1) {
        //             console.log("Found task in Redux state:", state.projects[projectIndex].tasks[taskIndex]);

        //             let updatedTasks;
        //             if (action.payload.task.isDeleted) {
        //                 console.log("coming inside isDeleted");
        //                 updatedTasks = state.projects[projectIndex].tasks.filter(task => task._id !== action.payload.task._id);
        //             } else {
        //                 console.log("coming outside isDeleted");
        //                 updatedTasks = state.projects[projectIndex].tasks.map(task =>
        //                     task._id === action.payload.task._id ? action.payload.task : task
        //                 );
        //             }

        //             // Update project reference in Redux state
        //             state.projects = [
        //                 ...state.projects.slice(0, projectIndex),
        //                 { ...state.projects[projectIndex], tasks: updatedTasks },
        //                 ...state.projects.slice(projectIndex + 1),
        //             ];
        //         } else {
        //             console.log("Task not found in project tasks.");
        //         }
        //     }
        // },


        // updateTask: (state, action) => {
        //     // console.log("coming payload inside update Task reducer ==>", action.payload)
        //     console.log("current state ==>", state.projects.filter((item) => item._id == action.payload.projectId))
        //     state.isFetching = false;
        //     const projectIndex = state.projects.findIndex(project => project._id === action.payload.projectId);
        //     if (projectIndex !== -1) {
        //         const taskIndex = state.projects[projectIndex].tasks.findIndex(task => task._id === action.payload.task._id);
        //         console.log("task index value bed=fore ==>", taskIndex)
        //         // if (taskIndex !== -1) {
        //         //     state.projects[projectIndex].tasks[taskIndex] = action.payload.task; // Update task in state
        //         // }

        //         // if (taskIndex !== -1) {
        //         //     if (action.payload.task.isDeleted) {
        //         //         console.log("coming inside isDeleted")
        //         //         // Remove the task if it is marked as deleted
        //         //         state.projects[projectIndex].tasks?.splice(taskIndex, 1);
        //         //     } else {
        //         //         console.log("coming outside isDeleted")
        //         //         // Update the task normally
        //         //         state.projects[projectIndex].tasks[taskIndex] = action.payload.task;
        //         //     }
        //         // }
        //         if (taskIndex !== -1) {
        //             console.log("Found task in Redux state:", state.projects[projectIndex].tasks[taskIndex]);

        //             if (action.payload.task.isDeleted) {
        //                 console.log("coming inside isDeleted");
        //                 state.projects[projectIndex] = {
        //                     ...state.projects[projectIndex],
        //                     tasks: state.projects[projectIndex].tasks?.filter(task => task._id !== action.payload.task._id),
        //                 };
        //             } else {
        //                 console.log("coming outside isDeleted");
        //                 state.projects[projectIndex].tasks[taskIndex] = action.payload.task;
        //             }
        //         } else {
        //             console.log("Task not found in project tasks.");
        //         }
        //     }
        // },

        // // New Reducer to Add Task to a Project
        // addTaskToProject: (state, action) => {
        //     const { projectId, newTask } = action.payload;
        //     console.log("coming payload in reducer ==>", newTask)
        //     const projectIndex = state.projects.findIndex(project => project._id === projectId);
        //     if (projectIndex !== -1) {
        //         state.projects[projectIndex].tasks.push(newTask);
        //     }
        // }

        // addTaskToProject: (state, action) => {
        //     const { projectId, newTask } = action.payload;
        //     console.log("coming payload in reducer ==>", newTask);

        //     const projectIndex = state.projects.findIndex(project => project._id === projectId);
        //     if (projectIndex !== -1) {
        //         state.projects[projectIndex] = {
        //             ...state.projects[projectIndex],
        //             tasks: [...state.projects[projectIndex].tasks, newTask], // Return a new array
        //         };
        //     }
        // }

        // addTaskToProject: (state, action) => {
        //     const { projectId, newTask } = action.payload;
        //     console.log("coming payload in reducer ==>", newTask);

        //     const projectIndex = state.projects.findIndex(project => project._id === projectId);
        //     if (projectIndex !== -1) {
        //         // Clone the project to trigger Redux state update
        //         const updatedProject = {
        //             ...state.projects[projectIndex],
        //             tasks: [...state.projects[projectIndex].tasks, newTask]
        //         };

        //         // Update projects array with new reference
        //         state.projects = [
        //             ...state.projects.slice(0, projectIndex),
        //             updatedProject,
        //             ...state.projects.slice(projectIndex + 1),
        //         ];
        //     }
        // }
        addTaskToProject: (state, action) => {
            const { projectId, newTask } = action.payload;
            const projectIndex = state.projects.findIndex(project => project._id === projectId);

            if (projectIndex !== -1) {
                state.projects[projectIndex] = {
                    ...state.projects[projectIndex],
                    tasks: [...state.projects[projectIndex].tasks, newTask] // Ensure new array
                };
            }
        }




    },
});

export const { getProjectsStart, getProjectsSuccess, getProjectsFailure, deleteProjectStart, deleteProjectSuccess, deleteProjectFailure, deleteProjectTaskSuccess, addProjectFailure, addProjectStart, addProjectSuccess, updateProjectStart, updateProjectSuccess, updateProjectFailure, updateTask, addTaskToProject } = clientReducer.actions;
export default clientReducer.reducer;

