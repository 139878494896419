// socketContext.js
import React, { createContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { BASE_URL, SOCKET_URL, userRequest } from "../../requestMethod";
import { addTaskToProject, updateTask } from "../../redux/projectRedux";
import { useDispatch } from "react-redux";
import { store } from "../../redux/store";

const SocketContext = createContext(null);

export const SocketProvider = ({ children, userId }) => {
    const dispatch = useDispatch();
    const socket = useRef(null);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const notificationSound = useRef(null);
    const [isAudioEnabled, setIsAudioEnabled] = useState(false); // Track if audio is enabled

    // Fetch missed notifications from API when user logs in
    const fetchNotifications = async () => {
        try {
            const { data } = await userRequest.get(`/notification/all`);
            setNotifications(data || []);
            setUnreadCount(data || 0);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    useEffect(() => {
        // Enable audio on user interaction
        const enableAudio = () => {
            setIsAudioEnabled(true);
            document.removeEventListener("click", enableAudio);
        };

        document.addEventListener("click", enableAudio);
        setNotifications([]);

        if (userId) {
            fetchNotifications();
            // Create socket connection with better error handling and configuration
            socket.current = io(SOCKET_URL, {
                transports: ["websocket", "polling"],  // Allow fallback to polling if websocket fails
                withCredentials: true,
                reconnection: true,
                reconnectionAttempts: Infinity,  // Keep trying to reconnect
                reconnectionDelay: 1000,  // Start with 1 second delay
                reconnectionDelayMax: 5000,  // Maximum delay between reconnection attempts
                timeout: 20000,  // Increase connection timeout
            });

            // Add connection status logging
            socket.current.on("connecting", () => {
                // console.log("Attempting to connect to socket server...");
            });

            socket.current.on("connect", () => {
                // console.log("Connected to socket server:", socket.current.id);
                socket.current.emit("register", userId);
            });

            socket.current.on("connect_error", (error) => {
                console.error("Socket connection error:", error.message);
                // You might want to add some UI feedback here
            });

            socket.current.on("disconnect", (reason) => {
                console.log("Socket disconnected:", reason);
            });

            socket.current.on("new-notification", (notification) => {
                setNotifications((prev) => [notification, ...prev]); // Add notification
                setUnreadCount((prev) => prev + 1); // Increment unread count

                if (notification.type === "task-assigned") {
                    dispatch(addTaskToProject({
                        projectId: notification.task.projectId,
                        newTask: notification.task
                    }));

                    // Delay update to prevent race condition
                    // setTimeout(() => {
                    //     dispatch(updateTask({ projectId: notification.task.projectId, task: notification.task }));
                    // }, 100);
                    // Delay update to ensure React has re-rendered
                    // setTimeout(() => {
                    //     dispatch(updateTask({ projectId: notification.task.projectId, task: notification.task }));
                    // }, 500);  // Adjust delay if needed

                    // Debugging: Log Redux state after dispatching addTaskToProject
                    // setTimeout(() => {
                    //     console.log("Redux state after adding task:", store.getState().projects);

                    //     dispatch(updateTask({ projectId: notification.task.projectId, task: notification.task }));
                    // }, 500);
                } else if (notification.type === "task-status-updated") {
                    dispatch(updateTask({ projectId: notification.task.projectId, task: notification.task })); // Update task in Redux
                }

                // if (notification?.task) {
                //     dispatch(updateTask({ projectId: notification.task.projectId, task: notification.task })); // Update task in Redux
                // }

                // Play notification sound if audio is enabled
                if (notificationSound.current && isAudioEnabled) {
                    notificationSound.current
                        .play()
                        .catch((error) => console.log("Audio playback failed:", error));
                }

                // Trigger browser push notification
                if (Notification.permission === "granted") {
                    new Notification(notification.title, {
                        body: notification.description,
                        icon: `${process.env.PUBLIC_URL}/Uniworld-Studios-favicon.svg`,
                    });
                }
            });

            // // Handle missed notifications
            // socket.current.on("missed-notifications", (missedNotifications) => {
            //     setNotifications((prev) => [...missedNotifications, ...prev]);
            //     setUnreadCount((prev) => prev + missedNotifications.length);
            // });

            return () => {
                socket.current.disconnect(); // Cleanup on unmount
            };
        }

        return () => {
            document.removeEventListener("click", enableAudio); // Cleanup listener
        };
    }, [userId, isAudioEnabled]);

    // Request notification permission
    useEffect(() => {
        if (Notification.permission !== "granted") {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    // console.log("Notification permission granted.");
                }
            });
        }
    }, []);

    // Function to mark notifications as read
    const markNotificationsAsRead = () => setUnreadCount(0);

    return (
        <SocketContext.Provider
            value={{ socket: socket.current, notifications, setNotifications, unreadCount, markNotificationsAsRead }}
        >
            {/* Add an invisible audio element */}
            <audio ref={notificationSound} src={`${process.env.PUBLIC_URL + "/audio/notification-alert.mp3"}`} preload="auto" />
            {children}
        </SocketContext.Provider>
    );
};

export default SocketContext;



// import React, { createContext, useContext, useEffect, useState } from "react";
// import { io } from "socket.io-client";
// import useCurrentUser from "../../custom-hooks/useCurrentUser";

// // Create SocketContext
// const SocketContext = createContext();

// export const useSocket = () => {
//     return useContext(SocketContext);
// };

// export const SocketProvider = ({ children }) => {
//     const [socket, setSocket] = useState(null);
//     const currentUser = useCurrentUser(); // Get the current user


//     useEffect(() => {
//         // Initialize the socket connection once
//         const socketInstance = io("http://localhost:3006");

//         // Set the socket instance
//         setSocket(socketInstance);

//         // Register the user with their socket ID
//         socketInstance.emit("register", currentUser._id);

//         // Log registration for debugging
//         console.log("User registered with ID:", currentUser._id);

//         return () => {
//             socketInstance.disconnect(); // Cleanup on unmount
//         };
//     }, [currentUser._id]);

//     return (
//         <SocketContext.Provider value={socket}>
//             {children}
//         </SocketContext.Provider>
//     );
// };
