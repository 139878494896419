import "./AllTasksTable.css";
import { useParams } from "react-router-dom";
import DataTable from "../../../common-components/tasks-table/DataTable";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAngleDown,
  FaAngleUp,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import NoResults from "../../../common-components/no-results/NoResults";
import { getAllProjects } from "../../../redux/apiCalls";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";
import { Row } from "react-bootstrap";

const AllTasksTable = () => {
  const dispatch = useDispatch();
  const { filter: taskType } = useParams();

  const { projects, isFetching } = useSelector((state) => state.projects);

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  const user = useCurrentUser();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const [sortCriteria, setSortCriteria] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const isFieldSorted = (field) => sortCriteria?.includes(field);
  const [selectedProject, setSelectedProject] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  // Sorting function for multi-level sorting
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Filter tasks based on the selected project
  // const filterTasksByProject = () => {
  //   if (!user) {
  //     return []; // Return an empty array if user details are not available
  //   }

  //   if (selectedProject) {
  //     return projects?.reduce((acc, project) => {
  //       if (project._id === selectedProject._id) {
  //         const projectTasks = project.tasks
  //           ?.filter((task) => task.assignedTo?.id === user._id) // Filter tasks assigned to the logged-in user
  //           ?.filter((task) => task.status == "delayed")?.map((task) => ({
  //             ...task,
  //             projectTitle: project.title,
  //           }));
  //         return [...acc, ...projectTasks];
  //       }
  //       return acc;
  //     }, []);
  //     // return projects?.reduce((acc, project) => {
  //     //   if (project._id === selectedProject._id) {
  //     //     const projectTasks = project.tasks
  //     //       ?.filter((task) => task.assignedTo?.id === user._id) // Filter tasks assigned to the logged-in user
  //     //       ?.map((task) => ({
  //     //         ...task,
  //     //         projectTitle: project.title,
  //     //       }));
  //     //     return [...acc, ...projectTasks];
  //     //   }
  //     //   return acc;
  //     // }, []);
  //   }

  //   // Return all tasks assigned to the user if no project is selected
  //   return projects?.reduce((acc, project) => {
  //     const projectTasks = project.tasks
  //       ?.filter((task) => task.assignedTo?.id === user._id) // Filter tasks assigned to the logged-in user
  //       ?.map((task) => ({
  //         ...task,
  //         projectTitle: project.title,
  //       }));
  //     return [...acc, ...projectTasks];
  //   }, []);
  // };

  // Filter tasks based on the selected project and filter criteria
  const filterTasksByProject = () => {
    if (!user) {
      return []; // Return an empty array if user details are not available
    }

    const filterTasks = (projectTasks, project) => {
      return projectTasks
        ?.filter((task) => task.assignedTo?.id === user._id) // Filter tasks assigned to the logged-in user
        ?.filter((task) => {
          if (taskType === "delayed") {
            return task.status === "delayed"; // Only include delayed tasks if filter=delayed
          }
          return true; // Include all tasks otherwise
        })
        ?.map((task) => ({
          ...task,
          projectTitle: project.title, // Use project.title here
        }));
    };

    if (selectedProject) {
      return projects?.reduce((acc, project) => {
        if (project._id === selectedProject._id) {
          const projectTasks = filterTasks(project.tasks, project);
          return [...acc, ...projectTasks];
        }
        return acc;
      }, []);
    }

    // Return all tasks assigned to the user if no project is selected
    return projects?.reduce((acc, project) => {
      const projectTasks = filterTasks(project.tasks, project);
      return [...acc, ...projectTasks];
    }, []);
  };

  // useEffect(() => {
  //   filterTasksByProject()
  // },[taskType])

  // Filter tasks based on the selected project and filter criteria
  // Filter tasks based on the selected project and filter criteria
  // const filterTasksByProject = () => {
  //   if (!user) {
  //     return []; // Return an empty array if user details are not available
  //   }

  //   const filterTasks = (projectTasks, project) => {
  //     return projectTasks
  //       ?.filter((task) => task.assignedTo?.id === user._id) // Filter tasks assigned to the logged-in user
  //       ?.filter((task) => {
  //         if (taskType === "delayed") {
  //           return task.status === "delayed"; // Only include delayed tasks if filter=delayed
  //         }
  //         return true; // Include all tasks otherwise
  //       })
  //       ?.map((task) => ({
  //         ...task,
  //         projectTitle: project.title, // Use project.title here
  //       }));
  //   };

  //   if (selectedProject) {
  //     return projects?.reduce((acc, project) => {
  //       if (project._id === selectedProject._id) {
  //         const projectTasks = filterTasks(project.tasks, project);
  //         return [...acc, ...projectTasks];
  //       }
  //       return acc;
  //     }, []);
  //   }

  //   // Return all tasks assigned to the user if no project is selected
  //   return projects?.reduce((acc, project) => {
  //     const projectTasks = filterTasks(project.tasks, project);
  //     return [...acc, ...projectTasks];
  //   }, []);
  // };

  // Apply sorting to filtered tasks
  const filteredTasks = filterTasksByProject()?.sort((a, b) => {
    if (!sortConfig.key) {
      return 0; // No sorting criteria set
    }

    let order = sortConfig.direction === "asc" ? 1 : -1;

    if (sortConfig.key === "endDate") {
      return order * (new Date(a.endDate) - new Date(b.endDate));
    }

    if (sortConfig.key === "priority") {
      // Assuming priorities are like "low", "mid", "high", you can define a priority order
      const priorityOrder = { low: 1, mid: 2, high: 3 };
      return order * (priorityOrder[a.priority] - priorityOrder[b.priority]);
    }

    if (sortConfig.key === "projectTitle") {
      return order * a.projectTitle.localeCompare(b.projectTitle);
    }

    return 0;
  });

  // Apply sorting to filtered tasks
  // const filteredTasks = filterTasksByProject()?.sort((a, b) => {
  //   if (!sortConfig.key) {
  //     return 0; // No sorting criteria set
  //   }

  //   let order = sortConfig.direction === "asc" ? 1 : -1;

  //   if (sortConfig.key === "endDate") {
  //     return order * (new Date(a.endDate) - new Date(b.endDate));
  //   }

  //   if (sortConfig.key === "priority") {
  //     // Assuming priorities are like "low", "mid", "high", you can define a priority order
  //     const priorityOrder = { low: 1, mid: 2, high: 3 };
  //     return order * (priorityOrder[a.priority] - priorityOrder[b.priority]);
  //   }

  //   if (sortConfig.key === "projectTitle") {
  //     return order * a.projectTitle.localeCompare(b.projectTitle);
  //   }

  //   return 0;
  // });

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Tasks" />
      <div className="action-btn-wrapper att-filters-wrapper custom-padding pb-0">
        <div className="att-filters">
          <p className="sort-by">Sort By:</p>
        </div>
        <div className="manage-project-dropdown">
          <button className="btn-edit" onClick={handleToggleDropdown}>
            {selectedProject ? selectedProject.title : "Project"}
            {isDropdownVisible ? (
              <FaAngleUp className="m-0" />
            ) : (
              <FaAngleDown className="m-0" />
            )}
          </button>
          {isDropdownVisible && (
            <ul className="dropdown-list">
              <li
                key="all"
                className="dropdown-item"
                onClick={() => setSelectedProject(null)}
              >
                All Projects
              </li>
              {projects?.map((project, index) => (
                <li
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleSelectProject(project)}
                >
                  {project.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Departments */}
        <div className="manage-project-dropdown">
          <button className="btn-edit" onClick={() => handleSort("endDate")}>
            Deadline
            {sortConfig.key === "endDate" &&
              (sortConfig.direction === "asc" ? (
                <FaLongArrowAltUp className="m-0" />
              ) : (
                <FaLongArrowAltDown className="m-0" />
              ))}
          </button>
        </div>

        {/* Sort by Priority */}
        <div className="manage-project-dropdown att-dropdown-btns">
          <button className="btn-edit" onClick={() => handleSort("priority")}>
            Priority
            {sortConfig.key === "priority" &&
              (sortConfig.direction === "asc" ? (
                <FaLongArrowAltUp className="m-0" />
              ) : (
                <FaLongArrowAltDown className="m-0" />
              ))}
          </button>
        </div>
      </div>
      {/* Tasks table */}
      <div className="task-table-container custom-padding">
        {filteredTasks?.length > 0 ? (
          <DataTable tasks={filteredTasks} />
        ) : (
          <NoResults />
        )}
      </div>
    </>
  );
};

export default AllTasksTable;
