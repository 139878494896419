import { eventWrapper } from "@testing-library/user-event/dist/utils";
import "./AllClients.css";
import { Col, Container, Row } from "react-bootstrap";
import HeaderNav from "../../sidebar/HeaderNav";
import ClientCard from "../../../../common-components/cards/client-card/ClientCard";
import AnnouncementBar from "../../../../common-components/announcement-bar/AnnouncementBar";
import useCurrentUser from "../../../../custom-hooks/useCurrentUser";
import { Link } from "react-router-dom";
import CommonButton from "../../../../common-components/button/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllClients } from "../../../../redux/apiCalls";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import NoResults from "../../../../common-components/no-results/NoResults";
import { hasPermission } from "../../../../config";

const AllClients = () => {
  const currentUser = useCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    getAllClients(dispatch);
  }, [dispatch]);

  const { isFetching, clients: allClients } = useSelector(
    (state) => state?.client
  );

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <div>
      <Container fluid className="p-0">
        <Row>
          <Col lg={12}>
            {/* breadcrumb */}
            <Breadcrumb mainTitle="Clients" />

            {/* All clients  */}
            <div className="clients-card-wrapper custom-padding pb-0">
              {hasPermission(currentUser, ["add_client"]) && (
                <div className="action-btn-wrapper pb-0">
                  <Link to={"/add-client"}>
                    <CommonButton
                      btnName="Add Client"
                      btnClass="btn-edit common-btn"
                    />
                  </Link>
                </div>
              )}

              {/* <ClientCard clientDetails={allClients} /> */}
            </div>
          </Col>
        </Row>
        <Row className="custom-padding">
          {allClients?.length > 0 ? (
            allClients?.map((clientDetail, index) => {
              return <ClientCard clientDetail={clientDetail} />;
            })
          ) : (
            <NoResults />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default AllClients;
