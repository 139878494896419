import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../common-components/breadcrumb/Breadcrumb";
import NotificationsData from "../../common-components/notifications/notifications-data/NotificationsData";
import { io } from "socket.io-client";
import useCurrentUser from "../../custom-hooks/useCurrentUser";
// import { useSocket } from "../../common-components/socket-provider/socketContext";
import SocketContext from "../../common-components/socket-provider/socketContext";
import Notification from "../../common-components/notifications/notification/Notification";

const AllNotifications = () => {
  const [limit, setLimit] = useState(30);
  const currentUser = useCurrentUser();
  // const socket = useSocket(); // Get the socket instance
  // const socket = useContext(SocketContext);

  const { notifications, setNotifications, unreadCount, markNotificationsAsRead } = useContext(SocketContext);

  // console.log("Socekt from all noti pahge ==>", socket)
  // const [notifications, setNotifications] = useState([]);
  // const [unreadCount, setUnreadCount] = useState(0);

  // useEffect(() => {
  //   if (!socket) return; // Exit if the socket is not initialized

  //   // Listen for new notifications
  //   socket.on("new-notification", (notification) => {
  //     console.log("Received notification:", notification);
  //     setNotifications((prev) => [notification, ...prev]); // Update the notification list
  //     setUnreadCount((prev) => prev + 1); // Update the unread count
  //   });

  //   // Cleanup on component unmount
  //   return () => {
  //     socket.off("new-notification"); // Remove the event listener
  //   };
  // }, [socket]);


  // useEffect(() => {
  //   if (!currentUser?._id) return; // Exit if currentUser._id is undefined

  //   // Initialize socket connection
  //   // const socket = io("http://localhost:3006");

  //   // Register the user with their socket ID
  //   socket.emit("register", currentUser?._id);

  //   // Listen for new notifications
  //   socket.on("new-notification", (notification) => {
  //     console.log("Received notification:", notification);
  //     setNotifications((prev) => [notification, ...prev]); // Update the notification list
  //     setUnreadCount((prev) => prev + 1); // Update the unread count
  //   });
  //   // socket.on("apply leave", (notification) => {
  //   //   console.log("Received notification:", notification);
  //   //   // setNotifications((prev) => [notification, ...prev]); // Update the notification list
  //   //   // setUnreadCount((prev) => prev + 1); // Update the unread count
  //   // });

  //   // Cleanup on component unmount
  //   return () => {
  //     socket.off("new-notification"); // Remove the event listener
  //     socket.disconnect(); // Disconnect the socket
  //   };
  // }, [currentUser?._id]);


  const handleMoreNotifications = () => {
    setLimit((prevlimit) => prevlimit + 10);
  };
  return (
    <div>
      <Breadcrumb mainTitle="All Notifications" />
      {/* <NotificationsData limit={limit} /> */}
      <div className="custom-padding">
        {notifications?.length > 0 ? (
          <div className="notification-container">
            {notifications?.map((noti, index) => (
              <Notification
                type={noti.type}
                key={index}
                icon={noti.icon}
                title={noti.title}
                description={noti.description}
                time={noti.time}
                // handleMarkSingleAsRead={handleMarkSingleAsRead}
                notificationId={noti._id}
                redirectedLink={noti.redirectedLink}
              />
            ))}
          </div>
        ) : (
          <div className="no-notification text-center">
            <p>No Notifications</p>
          </div>
        )}
      </div>

      {/* show more btn  */}
      <div className="show-more-btn-wrapper text-center">
        <p className="show-more-btn" onClick={handleMoreNotifications}>
          Show More
        </p>
      </div>
    </div>
  );
};

export default AllNotifications;
